import React from "react"
import permissions from "constants/permissions"
import Access, { useAccess } from "components/permissions/Access"
import PermissionsProtected from "components/permissions/PermissionsProtected"
import { buildAdminListPath, buildUserListPath } from "users/Routes"
import { ContactRostersViewPath, ContactRostersAdd, buildContactRostersViewDetailsPath } from "./Paths"
import { CONTACT_ROSTERS, useEnablements } from "data/enablements"
import { useHasPermissions } from "components/permissions/usePermissions"

const useContactRostersHomepageAccess = () => useAccess([permissions.contactRosters.view], ContactRostersViewPath)

const ContactRostersHomepageAccess = ({ children }) => {
  const [hasPermissions, path] = useContactRostersHomepageAccess()
  return <Access hasPermissions={hasPermissions} path={path} children={children} />
}

const useUsersHomepageAccess = () => useAccess([permissions.users.view], buildUserListPath())

const UsersHomepageAccess = ({ children }) => {
  const [hasPermissions, path] = useUsersHomepageAccess()
  return <Access hasPermissions={hasPermissions} path={path} children={children} />
}

const useAdministratorsListAccess = () => useAccess([permissions.users.view], buildAdminListPath())
const useIsContactRostersAccess = () => {
  const isActiveOrganizationEnabledForContactRosters = useEnablements(CONTACT_ROSTERS)
  const hasContactRostersViewPermissions = useHasPermissions([permissions.contactRosters.view])
  return isActiveOrganizationEnabledForContactRosters && hasContactRostersViewPermissions
}

const AdministratorsAccess = ({ children }) => {
  const [hasPermissions, path] = useAdministratorsListAccess()
  return <Access hasPermissions={hasPermissions} path={path} children={children} />
}

const ContactRostersAddAccess = ({ children }) => {
  return (
    <PermissionsProtected requiredPermissions={[permissions.contactRosters.add]}>
      {children(ContactRostersAdd)}
    </PermissionsProtected>
  )
}

const ContactRostersViewContactRosterDetailsAccess = ({ contactRosterId, children }) => {
  return (
    <PermissionsProtected requiredPermissions={[permissions.contactRosters.viewRosterDetails]}>
      {children(buildContactRostersViewDetailsPath(contactRosterId))}
    </PermissionsProtected>
  )
}

const ContactRostersDeleteAccess = ({ children }) => {
  return (
    <PermissionsProtected requiredPermissions={[permissions.contactRosters.delete]}>{children}</PermissionsProtected>
  )
}

export {
  ContactRostersHomepageAccess,
  useContactRostersHomepageAccess,
  UsersHomepageAccess,
  useUsersHomepageAccess,
  AdministratorsAccess,
  useAdministratorsListAccess,
  ContactRostersAddAccess,
  ContactRostersViewContactRosterDetailsAccess,
  ContactRostersDeleteAccess,
  useIsContactRostersAccess
}
