import React from "react"
import PermissionsProtected from "components/permissions/PermissionsProtected"
import permissions from "constants/permissions"
import { troubleshootingPath } from "./Paths"

export const TroubleshootingAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.ratings]}>
    {children(troubleshootingPath)}
  </PermissionsProtected>
)
