import React from "react"
import BaseLayout from "./BaseLayout"
import ToolboxSidebar from "./components/ToolboxSidebar"
import TopMenuLayout from "components/layouts/TopMenuLayout"

/**
 * The ToolboxLayout is an extension of the BasicHubLayout that adds a CompanyName header (with link to CompanySettings)
 * and the Hub Sidebar
 */
const ToolboxLayout = ({ children }) => (
  <BaseLayout>
    <TopMenuLayout />
    <div className="content-layout">
      <div className="col-sidebar">
        <ToolboxSidebar />
      </div>
      <div id="main-content" className="col-page-content" tabIndex="-1">
        {children}
      </div>
    </div>
  </BaseLayout>
)

const renderToolboxLayout = Component => {
  return props => (
    <ToolboxLayout>
      <Component {...props} />
    </ToolboxLayout>
  )
}

export default ToolboxLayout
export { renderToolboxLayout }
