import React, { lazy, Suspense } from "react"
import { stringify } from "query-string"
import PermissionsAndSignedAgreementProtectedRoute from "components/PermissionsAndSignedAgreementProtectedRoute"
import PageSpinner from "components/spinner/PageSpinner"
import { renderHubLayout } from "../components/layouts/HubLayout"
import PermissionsProtected from "components/permissions/PermissionsProtected"
import permissions from "constants/permissions"
import { Switch } from "react-router-dom"
import { ContactRostersViewPath, ContactRostersViewDetailsPath } from "ContactRosters/Paths"
import { CONTACT_ROSTERS, useEnablements } from "data/enablements"

const renderLoadingPage = Component => props => (
  <Suspense fallback={<PageSpinner />}>
    <Component {...props} />
  </Suspense>
)

const UsersListPath = "/users"
const AdminListPath = "/users/administrators"

const Paths = Object.freeze({
  UserProfile: "/users/:userName",
  UserRatings: "/users/:userName/ratings",
  UserOverview: "/users/:userName/overview",
  UserAdd: "/users/new",
  UserAddNoService: "/users/new/noservice",
  UserClone: "/users/:userName/clone",
  ActivateDevice: "/users/:userName/device/activate",
  UserDeactivate: "/users/:userName/deactivate",
  UserServiceUpdate: "/users/:userName/update"
})

/*
  This array is used to create the paths for the user profile and its tabs.
  If you add a new tab, please make sure to ad it at the end of this array.

  You will also need to make sure that the access component wraps your tab
  with the same path on render.
 */
const profilePaths = [Paths.UserProfile, Paths.UserRatings, Paths.UserOverview]
const userPagesHomePath = "/users"

export const buildUserListPath = queryParams => {
  const withDefault = {
    ...queryParams,
    status: "active"
  }
  const serializedQueryParams = stringify(withDefault)
  return `${userPagesHomePath}?${serializedQueryParams}`
}

export const buildAdminListPath = queryParams => {
  const withDefault = {
    ...queryParams,
    status: "active"
  }
  const serializedQueryParams = stringify(withDefault)
  return `${userPagesHomePath}/administrators?${serializedQueryParams}`
}

const buildUserProfilePath = userName => `/users/${userName}`
const buildUserRatingsPath = userName => `/users/${userName}/ratings`
const buildUserClonePath = userName => `/users/${userName}/clone`
const buildUserDeactivatePath = userName => `/users/${userName}/deactivate`
const buildActivateDevicePath = userName => `/users/${userName}/device/activate`
const buildUserServiceUpdatePath = userName => `/users/${userName}/update`

const UserProfileAccess = ({ children, userName }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.view]}>
    {children(buildUserProfilePath(userName))}
  </PermissionsProtected>
)

//  TODO: Add proper permissions
const UserRatingsAccess = ({ children, userName }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.view]}>
    {children(buildUserRatingsPath(userName))}
  </PermissionsProtected>
)

const UserListAccess = ({ children, locationIds }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.view]}>
    {children(buildUserListPath({ locationId: locationIds }))}
  </PermissionsProtected>
)

const UserListAccessWithoutPath = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.view]}>{children}</PermissionsProtected>
)

const AdminListAccess = ({ children, locationIds }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.manageRoles]}>
    {children(buildAdminListPath({ locationId: locationIds }))}
  </PermissionsProtected>
)

const UserAddAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.add]}>{children(Paths.UserAdd)}</PermissionsProtected>
)

const UserServiceUpdateAccess = ({ children, userName }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.upgrade]}>
    {children(buildUserServiceUpdatePath(userName))}
  </PermissionsProtected>
)

const UserCloneAccess = ({ children, userName }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.add]}>
    {children(buildUserClonePath(userName))}
  </PermissionsProtected>
)

const UserReactivateAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.reactivate]}>{children}</PermissionsProtected>
)

const UserDeactivateAccess = ({ children, userName }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.deactivate]}>
    {children(buildUserDeactivatePath(userName))}
  </PermissionsProtected>
)

const UserExportAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.export]}>{children}</PermissionsProtected>
)

const UserSendWelcomeEmailAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.sendWelcomeEmail]}>{children}</PermissionsProtected>
)

const UserResetPasswordAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.resetPassword]}>{children}</PermissionsProtected>
)

const UserEditNumberAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.changePhoneNumber]}>{children}</PermissionsProtected>
)

const UserActivateDeviceAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.devices.bindHandset]}>{children}</PermissionsProtected>
)

const UserAddFaxAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.addFax]}>{children}</PermissionsProtected>
)

const UserEditIdmAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.idmEditUserProvider]}>{children}</PermissionsProtected>
)

const UserAddRecordingAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.addRecording]}>{children}</PermissionsProtected>
)

const UserEditRecordingAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.addRecording]}>{children}</PermissionsProtected>
)

const UserRemoveServiceAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.serviceDeprovision]}>{children}</PermissionsProtected>
)

const UserAddDiscoverAccess = ({ children, userName }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.addDiscovery]}>{children}</PermissionsProtected>
)

const UserRemoveDiscoverAccess = ({ children, userName }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.serviceDeprovision]}>{children}</PermissionsProtected>
)

const UserAddSMSAccess = ({ children, userName }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.addSMS]}>{children}</PermissionsProtected>
)

const UserRemoveSMSAccess = ({ children, userName }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.serviceDeprovision]}>{children}</PermissionsProtected>
)

const UserAddContactCenterAccess = ({ children, userName }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.addContactCenter]}>{children}</PermissionsProtected>
)

const UserRemoveContactCenterAccess = ({ children, userName }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.serviceDeprovision]}>{children}</PermissionsProtected>
)

const SwitchCustomerAccessWithoutPath = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.switchCustomer]}>{children}</PermissionsProtected>
)

const EditRolesPermissionsAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.manageRoles]}>{children}</PermissionsProtected>
)

const UserViewRostersAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.contactRosters.viewRostersOnUserProfile]}>
    {children}
  </PermissionsProtected>
)

export {
  UserProfileAccess,
  UserRatingsAccess,
  UserCloneAccess,
  UserListAccess,
  UserListAccessWithoutPath,
  AdminListAccess,
  UserAddAccess,
  UserExportAccess,
  UserServiceUpdateAccess,
  UserReactivateAccess,
  UserDeactivateAccess,
  UserSendWelcomeEmailAccess,
  UserResetPasswordAccess,
  SwitchCustomerAccessWithoutPath,
  UserActivateDeviceAccess,
  buildActivateDevicePath,
  UserEditNumberAccess,
  EditRolesPermissionsAccess,
  UserAddFaxAccess,
  UserAddRecordingAccess,
  UserEditRecordingAccess,
  UserAddDiscoverAccess,
  UserRemoveDiscoverAccess,
  UserAddSMSAccess,
  UserRemoveSMSAccess,
  UserAddContactCenterAccess,
  UserRemoveContactCenterAccess,
  UserEditIdmAccess,
  UserRemoveServiceAccess,
  buildUserProfilePath,
  buildUserRatingsPath,
  UsersListPath,
  AdminListPath,
  UserViewRostersAccess,
  buildUserServiceUpdatePath,
  userPagesHomePath
}
