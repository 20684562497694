import React, { useState } from "react"
import classNames from "classnames"
import { FormattedMessage } from "react-intl"
import { NavLink } from "react-router-dom"
import HubSidebarSubmenuItem from "./HubSidebarSubmenuItem"
import _castArray from "lodash/castArray"

const HubSidebarSubmenu = ({
  children,
  isSubmenuOpen,
  onSubmenuToggle,
  isActive,
  submenuName,
  isLoaded,
  toggleButtonTranslation
}) => {
  const [shouldBeAnimated, setShouldBeAnimated] = useState(false)
  const onClick = () => {
    onSubmenuToggle(submenuName)
    setShouldBeAnimated(true)
  }

  if (!isLoaded) return null

  // if there is only 1 child in children, it will not be within an array
  // place it in an array if needed
  const animationSpeed = 0.3

  const listItems = _castArray(children).filter(child => !!child)

  return (
    <li>
      <button
        id={submenuName}
        onClick={onClick}
        className={classNames({ active: isActive })}
        aria-expanded={isSubmenuOpen}
        aria-controls={`${submenuName}-submenu`}
      >
        <i className={`side-bar__icon icon-${submenuName}`} />
        <span className="side-bar__label">
          <FormattedMessage id={toggleButtonTranslation} />
        </span>
        <i
          className={classNames(
            { "icon-arrow-up": isSubmenuOpen },
            { "icon-arrow-down": !isSubmenuOpen },
            "submenu-caret"
          )}
        />
      </button>
      <ul
        id={`${submenuName}-submenu`}
        className={classNames(
          "submenu",
          { "open-menu": isSubmenuOpen }, // open/closed needed separately for transition in/out
          { "closed-menu": !isSubmenuOpen },
          { "is-animated": shouldBeAnimated }
        )}
        aria-hidden={isSubmenuOpen ? "false" : "true"}
      >
        {listItems.map((child, index) => {
          const id = child?.props?.id

          if (!id) return null

          return (
            <HubSidebarSubmenuItem
              isSubmenuOpen={isSubmenuOpen}
              itemAnimationSpeed={shouldBeAnimated ? `${animationSpeed}s` : 0}
              key={`${submenuName}-item-${id}`}
              onClick={() => onClick()}
              zIndex={listItems.length - index}
            >
              {child}
            </HubSidebarSubmenuItem>
          )
        })}
      </ul>
    </li>
  )
}

function SidebarSubmenuItem({ to, isSubmenuOpen, children, ...props }) {
  return (
    <NavLink exact to={to} tabIndex={isSubmenuOpen ? "0" : "-1"} {...props}>
      <span className="link-text">{children}</span>
    </NavLink>
  )
}

export default HubSidebarSubmenu
export { SidebarSubmenuItem }
