import React from "react"
import { Route, Switch } from "react-router"

import { error404Path, errorUnauthorizedPath } from "./Paths"
import Error404 from "./Error404"
import ErrorUnauthorized from "./ErrorUnauthorized"

const ErrorsRoutes = () => (
  <Switch>
    <Route path={error404Path} exact component={Error404} />
    <Route path={errorUnauthorizedPath} exact component={ErrorUnauthorized} />
  </Switch>
)

export default ErrorsRoutes
