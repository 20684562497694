import React from "react"
import { UserAvatar } from "components/layouts/components/UserAvatar"
import HeaderLoggedInUserDescription from "components/layouts/components/HeaderLoggedInUserDescription"
import { Collapse, DropdownMenu, DropdownToggle, Nav, UncontrolledDropdown } from "reactstrap"
import { getUser } from "lib/user-session"

type UserMenuProps = { isOpen: boolean }
const UserMenu: React.FunctionComponent<UserMenuProps> = ({ isOpen }) => {
  const user = getUser()

  return (
    <Collapse isOpen={isOpen} navbar>
      <Nav id="header-right" className="ml-auto pr-3" navbar>
        <UncontrolledDropdown nav className="nav-item">
          <DropdownToggle nav>
            <UserAvatar className="icon" user={user} />
            <span className="member-name" data-before-content={user.fullName} />
            <i className="icon-arrow-down" />
          </DropdownToggle>
          <DropdownMenu
            modifiers={{
              computeStyle: {
                fn: (data, options) => ({
                  ...data,
                  styles: {
                    ...data.styles,
                    left: "auto",
                    right: "0"
                  }
                })
              }
            }}
          >
            <HeaderLoggedInUserDescription />
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Collapse>
  )
}

export default UserMenu
