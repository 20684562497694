import { useAsync } from "react-use"
import { getUser } from "lib/user-session"
import { useQueryParams, StringParam } from "use-query-params"
import { getOrganization } from "@fuze/apis-foundry/dist/organizations"

import { getAuthenticationState } from "@fuze/services-auth"
import { register } from "lib/appcues"
import { identify, track } from "data/analytics"

export default function useAuthentication() {
  const [queryParams, setQueryParams] = useQueryParams({
    statusCode: StringParam,
    message: StringParam,
    token: StringParam
  })

  function clearQueryParameters() {
    setQueryParams({
      token: undefined,
      statusCode: undefined,
      message: undefined
    })
  }

  const { value: status, loading, error } = useAsync(async () => {
    const { token: shortLivedToken } = queryParams
    const authenticationState = await getAuthenticationState(shortLivedToken)

    if (authenticationState === "ready" && shortLivedToken) {
      await identifyUser()
      await track("Logged in")
      clearQueryParameters()
    }

    return authenticationState
  })

  return [status, loading, error]
}

async function identifyUser() {
  // TODO Differentiate between a user logging in and refreshing the page
  const organizationData = await getOrganization(getUser().tenantKey)
  identify(getUser())
  register(getUser(), organizationData)
}
