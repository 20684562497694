import React from "react"
import { Button } from "reactstrap"
import { getOrganization, getUser } from "lib/user-session"
import useOrganization from "hooks/useOrganization"
import SwitchOrganizationModal from "../../components/SwitchOrganizationModal"
import { isReseller } from "../../components/SwitchOrganization"
import { useToggle } from "react-use"
import permissions from "constants/permissions"
import { useHasPermissions } from "components/permissions/usePermissions"
import { useIntl } from "react-intl"

const TenantSwitcher = ({ allowCustomerSwitching }) => {
  const { formatMessage } = useIntl()
  const organizationCode = getOrganization()
  const [organization] = useOrganization(organizationCode)
  const [isShowModal, toggleModal] = useToggle(false)
  const isAllowedToSwitchCustomer = useHasPermissions([permissions.users.switchCustomer])

  const showSwitchButton = isReseller(getUser().tenantKey) && allowCustomerSwitching

  const onToggle = () => toggleModal()

  const TenantName = () => (
    <span id="customer-name" className="tenant-switcher__organization-name">
      {organization?.name}
    </span>
  )

  if (!showSwitchButton || !isAllowedToSwitchCustomer) {
    return (
      <div className="tenant-switcher">
        <TenantName />
      </div>
    )
  }

  return (
    <>
      <Button
        onClick={onToggle}
        className="tenant-switcher tenant-switcher--button"
        aria-label={formatMessage({ id: "navigation.tenantSwitcher.button" }, { tenant: organization?.name })}
      >
        <TenantName />
        <i className={"icon-switch_icon tenant-switcher__icon"} />
      </Button>
      <SwitchOrganizationModal isOpen={isShowModal} toggle={onToggle} />
    </>
  )
}

export default TenantSwitcher
