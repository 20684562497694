import React from "react"
import permissions from "constants/permissions"
import Access, { useAccess } from "components/permissions/Access"

import { devicesHomepagePath, activateDevicePath, claimDevicesPath, purchasePhonesPath } from "./Paths"

const useDevicesHomepageAccess = () => useAccess([permissions.devices.access], devicesHomepagePath)

const DevicesHomepageAccess = ({ children }) => {
  const [hasPermissions, path] = useDevicesHomepageAccess()
  return <Access hasPermissions={hasPermissions} path={path} children={children} />
}

const useActivateDeviceAccess = () => useAccess([permissions.devices.bindHandset], activateDevicePath)

const ActivateDeviceAccess = ({ children }) => {
  const [hasPermissions, path] = useActivateDeviceAccess()
  return <Access hasPermissions={hasPermissions} path={path} children={children} />
}

const useClaimDevicesAccess = () => useAccess([permissions.devices.claimHandset], claimDevicesPath)

const ClaimDevicesAccess = ({ children }) => {
  const [hasPermissions, path] = useClaimDevicesAccess()
  return <Access hasPermissions={hasPermissions} path={path} children={children} />
}

const usePurchasePhonesAccess = () => useAccess([permissions.devices.orderHardware], purchasePhonesPath)

const PurchasePhonesAccess = ({ children }) => {
  const [hasPermissions, path] = usePurchasePhonesAccess()
  return <Access hasPermissions={hasPermissions} path={path} children={children} />
}

export {
  DevicesHomepageAccess,
  useDevicesHomepageAccess,
  useActivateDeviceAccess,
  ActivateDeviceAccess,
  useClaimDevicesAccess,
  ClaimDevicesAccess,
  usePurchasePhonesAccess,
  PurchasePhonesAccess
}
