import React from "react"
import { createUtilityPhonesPath, utilityPhonesPath } from "./Paths"
import PermissionsProtected from "components/permissions/PermissionsProtected"
import permissions from "constants/permissions"
import AlloyProtected from "components/permissions/AlloyProtected"
import { ALLOY_UTILITY_SKU } from "constants/skus"
import Access, { useAccess } from "components/permissions/Access"

const CreateUtilityPhonesAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.utility.add]}>
    <AlloyProtected requiresSku={true} sku={ALLOY_UTILITY_SKU}>
      {children(createUtilityPhonesPath)}
    </AlloyProtected>
  </PermissionsProtected>
)

const useUtilityPhonesListAccess = () => useAccess([permissions.utility.view], utilityPhonesPath)

const UtilityPhonesListAccess = ({ children }) => {
  const [hasPermissions, path] = useUtilityPhonesListAccess()
  return <Access hasPermissions={hasPermissions} path={path} children={children} />
}

export { CreateUtilityPhonesAccess, UtilityPhonesListAccess, useUtilityPhonesListAccess }
