import React from "react"
import PermissionsProtected from "components/permissions/PermissionsProtected"
import permissions from "constants/permissions"
import { apiKeyManagementPath, apiKeyManagementNewPath } from "./Paths"
import { useAccess } from "components/permissions/Access"
import { useEnablements, DEVELOPER } from "data/enablements"

const useApiKeyManagementAccess = () => {
  const [permission] = useAccess([permissions.developerApi.developerApiAccess], apiKeyManagementPath)
  const isActiveOrganizationEnabledForDeveloper = useEnablements(DEVELOPER)
  const hasPermission = permission && isActiveOrganizationEnabledForDeveloper
  return [hasPermission, apiKeyManagementPath]
}

const ApiKeyManagementAccess = ({ children }) => {
  return (
    <PermissionsProtected requiredPermissions={[permissions.developerApi.developerApiAccess]}>
      {children(apiKeyManagementPath)}
    </PermissionsProtected>
  )
}

const ApiKeyManagementAddAccess = ({ children }) => {
  return (
    <PermissionsProtected requiredPermissions={[permissions.developerApi.developerApiKeyAdd]}>
      {children(apiKeyManagementNewPath)}
    </PermissionsProtected>
  )
}

const ApiKeyManagementEditAccess = ({ children }) => {
  return (
    <PermissionsProtected requiredPermissions={[permissions.developerApi.developerApiKeyAdd]}>
      {children}
    </PermissionsProtected>
  )
}

const ApiKeyManagementSuspendAccess = ({ children }) => {
  return (
    <PermissionsProtected requiredPermissions={[permissions.developerApi.developerApiKeySuspend]}>
      {children}
    </PermissionsProtected>
  )
}

const ApiKeyManagementRemoveAccess = ({ children }) => {
  return (
    <PermissionsProtected requiredPermissions={[permissions.developerApi.developerApiKeyRevoke]}>
      {children}
    </PermissionsProtected>
  )
}

export {
  useApiKeyManagementAccess,
  ApiKeyManagementAccess,
  ApiKeyManagementAddAccess,
  ApiKeyManagementEditAccess,
  ApiKeyManagementRemoveAccess,
  ApiKeyManagementSuspendAccess
}
