import React, { useState } from "react"
import { useAsync } from "react-use"
import useAuthentication from "./useAuthentication"
import { closeUserSession } from "@fuze/services-auth/dist/session-tasks"
import { removeToken } from "@fuze/services-auth/dist/token"
import { getUniversalLoginUrl } from "data/apis/authentication-utilities"

import PageSpinner from "components/spinner/PageSpinner"
import GeneralErrorPage from "Errors/GeneralErrorPage"
import AuthenticationContext from "components/authentication/AuthenticationContext"
import ErrorUnauthorized from "Errors/ErrorUnauthorized"
import SuspendedOrganization from "Errors/SuspendedOrganization"

import PermissionsProvider from "components/permissions/PermissionsProvider"

/**
 * Rule broken: a component should always return something.
 *
 * In this case, this is a very low-level component that is meant to match
 * the usage of react-router's Redirect component, which also doesn't actually
 * render anything
 *
 * For this reason, I didn't move this into it's own file because I want
 * the decision to use this component to be carefully considered.
 */
function ExternalRedirect({ to }) {
  window.location.href = to
  return null
}

/**
 * Private hook. I moved this outside of AuthenticationProvider so that
 * that component is easier to read.
 *
 * At this point, there is no reason to use this hook outside of this file.
 */
function useLoginUrl() {
  const { value: loginUrl, loading } = useAsync(async () => {
    return await getUniversalLoginUrl()
  })

  return [loginUrl, loading]
}

/**
 * Private hook. I moved this outside of AuthenticationProvider so that
 * that component is easier to read.
 *
 * At this point, there is no reason to use this hook outside of this file.
 */
function useLogout() {
  const [isLoggingOut, setLoggingOut] = useState(false)

  const { value: logoutUrl } = useAsync(async () => {
    return getUniversalLoginUrl("logout")
  })

  function logout() {
    removeToken() // remove the token
    closeUserSession() // Cleaning up user session variables

    setLoggingOut(true)
  }

  return [isLoggingOut, logoutUrl, logout]
}

/**
 * This provider wraps the entire App and is responsible for conditionally showing
 * authentication related redirects/errors. The reason that it looks for the
 * suspended status is because that comes back as an error from the first
 * Foundry call (get user)
 */
export default function AuthenticationProvider({ children }) {
  const [status, loading, error] = useAuthentication()
  const [loginUrl, urlLoading] = useLoginUrl()
  const [isLoggingOut, logoutUrl, logout] = useLogout()

  if (isLoggingOut) {
    return <ExternalRedirect to={logoutUrl} />
  }

  if (loading || urlLoading) {
    return <PageSpinner />
  }

  if (error) {
    return (
      <AuthenticationContext.Provider value={{ logout }}>
        <GeneralErrorPage />
      </AuthenticationContext.Provider>
    )
  }

  if (status === "unauthorized") {
    return (
      <AuthenticationContext.Provider value={{ logout }}>
        <ErrorUnauthorized />
      </AuthenticationContext.Provider>
    )
  }

  if (status === "login") {
    return <ExternalRedirect to={loginUrl} />
  }

  if (status === "suspended") {
    return (
      <AuthenticationContext.Provider value={{ logout }}>
        <SuspendedOrganization />
      </AuthenticationContext.Provider>
    )
  }

  return (
    <AuthenticationContext.Provider value={{ logout }}>
      <PermissionsProvider>{children}</PermissionsProvider>
    </AuthenticationContext.Provider>
  )
}
