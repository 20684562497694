import React from "react"
import PermissionsContext from "./PermissionsContext"
import ErrorUnauthorized from "Errors/ErrorUnauthorized"
import useCurrentUser from "hooks/useCurrentUser"

const Provider = ({ children }) => {
  const user = useCurrentUser()

  if (!user || !user.permissions || user.permissions.length === 0) {
    return <ErrorUnauthorized />
  }

  return <PermissionsContext.Provider value={{ permissions: user.permissions }}>{children}</PermissionsContext.Provider>
}

export default Provider
