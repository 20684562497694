import React, { lazy, Suspense } from "react"
import PermissionsAndSignedAgreementProtectedRoute from "components/PermissionsAndSignedAgreementProtectedRoute"
import PageSpinner from "components/spinner/PageSpinner"
import { renderHubLayout } from "../components/layouts/HubLayout"
import PermissionsProtected from "../components/permissions/PermissionsProtected"
import permissions from "constants/permissions"
import { Switch } from "react-router"
import { useEnablements, LOCATION_ADD } from "data/enablements"
import { useAccess } from "components/permissions/Access"

const renderLoadingPage = Component => props => (
  <Suspense fallback={<PageSpinner />}>
    <Component {...props} />
  </Suspense>
)

const locationsPath = "/locations"
const locationPath = "/locations/:locationId"
const newLocationPath = "/locations/new"
const locationsActionRequiredPath = "/locations/action-required"

const buildLocationPath = locationId => `/locations/${locationId}`

const LocationsAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.locations.view]}>
    {children(locationsPath)}
  </PermissionsProtected>
)

const LocationsActionRequiredAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.locations.view]}>
    {children(locationsActionRequiredPath)}
  </PermissionsProtected>
)

const LocationAccess = ({ children, locationId }) => (
  <PermissionsProtected requiredPermissions={[permissions.locations.view]}>
    {children(buildLocationPath(locationId))}
  </PermissionsProtected>
)

const LocationsAddAccess = ({ children }) => {
  const isOrganizationEnabledForNewLocation = useEnablements(LOCATION_ADD)
  if (!isOrganizationEnabledForNewLocation) {
    return null
  }

  return (
    <PermissionsProtected requiredPermissions={[permissions.locations.add]}>
      {children(newLocationPath)}
    </PermissionsProtected>
  )
}

const LocationsExportAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.locations.view]}>{children}</PermissionsProtected>
)

const useLocationAccess = () => useAccess([permissions.locations.view], buildLocationPath)
const useLocationListAccess = () => useAccess([permissions.locations.view], locationsPath)
const useLocationEditAccess = () => useAccess([permissions.locations.edit])
const useLocationCallerIdBulkEditAccess = () => useAccess([permissions.locations.bulkEditCallerId])

export {
  buildLocationPath,
  LocationsAccess,
  LocationAccess,
  LocationsActionRequiredAccess,
  LocationsAddAccess,
  locationsPath,
  locationsActionRequiredPath,
  LocationsExportAccess,
  useLocationAccess,
  useLocationListAccess,
  useLocationEditAccess,
  useLocationCallerIdBulkEditAccess
}
