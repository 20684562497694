import React, { Component } from "react"
import PropTypes from "prop-types"
import { FormattedMessage, injectIntl } from "react-intl"
import DebouncedInput from "react-debounce-input"
import { FormGroup, Label } from "reactstrap"

import EmptyTableMessage from "components/EmptyTableMessage"

class SwitchOrganizationSearchField extends Component {
  static propTypes = {
    onQueryChange: PropTypes.func.isRequired
  }

  render() {
    const { intl, onQueryChange } = this.props

    return (
      <div className="search-input-with-icon">
        <div className="icon-search">
          <DebouncedInput
            id="inputSearchOrganizations"
            className="form-control"
            placeholder={intl.formatMessage({ id: "desktopUpdates.searchOrganizations.input.placeholder" })}
            minLength={1}
            debounceTimeout={300}
            forceNotifyByEnter={true}
            forceNotifyOnBlur={true}
            onChange={event => onQueryChange(event.target.value)}
            autoFocus
            autoComplete="off"
          />
        </div>
      </div>
    )
  }
}

const SwitchOrganizationSearch = injectIntl(SwitchOrganizationSearchField)

export function SearchOrganisationField(props) {
  return (
    <FormGroup>
      <Label htmlFor="inputSearchOrganizations" className="sr-only">
        <FormattedMessage id="desktopUpdates.searchOrganizations.input.label" />
      </Label>
      <SwitchOrganizationSearch onQueryChange={props.onQueryChange} />
    </FormGroup>
  )
}

SearchOrganisationField.propTypes = { onQueryChange: PropTypes.func }

export function NoOrganisationFound(props) {
  return (
    <FormGroup>
      <EmptyTableMessage
        classes="mt-n4 mb-n4"
        title={
          <FormattedMessage
            id="desktopUpdates.searchOrganizations.emptyResults.header"
            values={{ query: props.query }}
          />
        }
      >
        <FormattedMessage id="desktopUpdates.searchOrganizations.emptyResults.message" />
      </EmptyTableMessage>
    </FormGroup>
  )
}

NoOrganisationFound.propTypes = { query: PropTypes.any }
