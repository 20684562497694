import React, { Component } from "react"
import SignedAgreementProtectedRoute from "components/SignedAgreementProtectedRoute"
import PermissionsProtectedRoute from "components/permissions/PermissionsProtectedRoute"

/**
 * This is a customization of react-route-dom Route that will first check if the user
 * has permissions to access this page. If it does have permissions, it will redirect to the legal page
 * if the user belongs to a customer who hasn't signed the latest EULA (end user license agreement)
 */
class PermissionsAndSignedAgreementProtectedRoute extends Component {
  render() {
    const { render, ...otherProps } = this.props

    return (
      <PermissionsProtectedRoute
        {...otherProps}
        render={props => {
          return <SignedAgreementProtectedRoute {...props} render={innerProps => render(innerProps)} />
        }}
      />
    )
  }
}

export default PermissionsAndSignedAgreementProtectedRoute
