import React from "react"

import TopMenuLayout from "./TopMenuLayout"

/**
 * The BasicHubLayout is an extension of the TopMenuLayout that adds a CompanyName header (with link to CompanySettings)
 *
 * It is basically a HubLayout without a sidebar
 */
const BasicHubLayout = ({ children }) => (
  <TopMenuLayout>
    <div className="content-layout">{children}</div>
  </TopMenuLayout>
)

const renderBasicHubLayout = Component => {
  return props => (
    <BasicHubLayout>
      <Component {...props} />
    </BasicHubLayout>
  )
}

export default BasicHubLayout
export { renderBasicHubLayout }
