import React, { lazy, Suspense } from "react"

import PermissionsAndSignedAgreementProtectedRoute from "components/PermissionsAndSignedAgreementProtectedRoute"
import RedirectRoute from "components/RedirectRoute"
import PageSpinner from "components/spinner/PageSpinner"
import { renderHubLayout } from "components/layouts/HubLayout"
import { isLoggedInOrganizationThinkingphones } from "lib/user-session"
import { didRequestsPath, legacyDidRequestsPath } from "./Paths"
import permissions from "constants/permissions"

const DidRequests = lazy(() => import("./DidRequests"))

const viewDidPermissions = [permissions.dids.viewDids]

const renderLoadingPage = Component => props => (
  <Suspense fallback={<PageSpinner />}>
    <Component {...props} />
  </Suspense>
)

const Routes = () => {
  return (
    <>
      {!isLoggedInOrganizationThinkingphones() && (
        <PermissionsAndSignedAgreementProtectedRoute
          requiredPermissions={viewDidPermissions}
          exact
          path={didRequestsPath}
          render={renderHubLayout(renderLoadingPage(DidRequests))}
        />
      )}
    </>
  )
}

export default Routes
