function isReseller(customerCode) {
  // for now: for internal use only
  return customerCode === "thinkingphones"
  // later: add logic that allows resellers to manage their customers
}

function searchOrganizations(query, orgs) {
  return orgs.filter(org => org.name.toLowerCase().indexOf(query) !== -1)
}

export { isReseller, searchOrganizations }
