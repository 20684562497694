import { useContext } from "react"
import PermissionsContext from "./PermissionsContext"
import { hasSomePermission } from "lib/permissions"

export default function usePermissions() {
  const { permissions } = useContext(PermissionsContext)
  return permissions
}

export function useHasPermissions(requiredPermissions, permissionsCheck = hasSomePermission) {
  const userPermissions = usePermissions()
  return permissionsCheck(userPermissions, requiredPermissions)
}
