import React, { lazy, Suspense } from "react"

import permissions from "constants/permissions"

import PermissionsAndSignedAgreementProtectedRoute from "components/PermissionsAndSignedAgreementProtectedRoute"
import PageSpinner from "components/spinner/PageSpinner"
import { renderToolboxLayout } from "components/layouts/ToolboxLayout"
import { rateCenterPath } from "./Paths"

const viewRateCenterPermissions = [permissions.dids.viewDidRateCenters]
const Ratecenters = lazy(() => import("./RateCenters"))

const renderLoadingPage = Component => props => (
  <Suspense fallback={<PageSpinner />}>
    <Component {...props} />
  </Suspense>
)

const Routes = () => {
  return (
    <>
      <PermissionsAndSignedAgreementProtectedRoute
        requiredPermissions={viewRateCenterPermissions}
        exact
        path={rateCenterPath}
        render={renderToolboxLayout(renderLoadingPage(Ratecenters))}
      />
    </>
  )
}

export default Routes
