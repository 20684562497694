import React from "react"
import PermissionsProtected from "components/permissions/PermissionsProtected"
import { hasSomePermission } from "lib/permissions"
import permissions from "constants/permissions"
import Access, { useAccess } from "components/permissions/Access"
import { useEnablements, NUMBER_PORTING } from "data/enablements"
import {
  portOrdersPath,
  updatePortinOrderPath,
  managePortinOrderPath,
  portOrdersReadyToSubmitPath,
  newPortinOrderPath,
  portOrdersInProgressPath,
  portOrdersArchivePath
} from "./Paths"
import { useCustomerLocation, US, CA } from "data/customer-location"
import { getBusinessDaysInBetween, getMomentDateFromString, getCurrentDate, dateToEST } from "./utilities"

const createOrUpdatePortinsPermissions = [permissions.portRequests.createOrUpdatePortinOrders]
const viewPortinsPermissions = [permissions.portRequests.viewPortinOrders]
const managePortinsPermissions = [
  permissions.portRequests.createOrUpdatePortinOrders,
  permissions.portRequests.viewPortinOrders
]
const portinsNoLocationRestrictionsPermissions = [permissions.portRequests.portinsNoLocationRestrictions]

const portinCreateOrUpdateAsFuzeUser = [permissions.portRequests.portinsNoLocationRestrictions]

const buildUpdatePortinOrderPath = portinOrderId => updatePortinOrderPath.replace(":portinOrderId", portinOrderId)
const buildManagePortinOrderPath = portinOrderId => managePortinOrderPath.replace(":portinOrderId", portinOrderId)

export const PortinAccess = ({ children }) => {
  const [hasPermissions, path] = usePortinAccess()
  return <Access hasPermissions={hasPermissions} path={path} children={children} />
}

const usePortinAccess = () => {
  const [hasViewPortinsPermissions, path] = useAccess(viewPortinsPermissions, portOrdersPath)
  const isNumberPortingEnabledForOrganization = useEnablements(NUMBER_PORTING)
  const [hasNoPortinLocationRestrictions] = useAccess(portinsNoLocationRestrictionsPermissions)
  const isCustomerHavingUSLocation = useCustomerLocation(US)
  const isCustomerHavingCALocation = useCustomerLocation(CA)

  const canUserSeeNumberPorts =
    isNumberPortingEnabledForOrganization &&
    (hasNoPortinLocationRestrictions || isCustomerHavingUSLocation || isCustomerHavingCALocation) &&
    hasViewPortinsPermissions
  return [canUserSeeNumberPorts, path]
}

export const usePortOrderReadyToSubmitAccess = () => {
  const [hasViewPortOrderReadyToSubmitPermissions, readyToSubmitPath] = useAccess(
    viewPortinsPermissions,
    portOrdersReadyToSubmitPath
  )
  return [hasViewPortOrderReadyToSubmitPermissions, readyToSubmitPath]
}

export const usePortOrderInProgressAccess = () => {
  const [hasViewPortOrderInProgressPermissions, inProgressPath] = useAccess(
    viewPortinsPermissions,
    portOrdersInProgressPath
  )
  return [hasViewPortOrderInProgressPermissions, inProgressPath]
}

export const usePortOrderArchiveAccess = () => {
  const [hasViewPortOrderNoActionPermissions, archivePath] = useAccess(viewPortinsPermissions, portOrdersArchivePath)
  return [hasViewPortOrderNoActionPermissions, archivePath]
}

export const useNewPortOrderAccess = () => {
  const [hasViewNewPortOrderPermissions, newPortOrderPath] = useAccess(viewPortinsPermissions, newPortinOrderPath)
  return [hasViewNewPortOrderPermissions, newPortOrderPath]
}

export const ViewPortinsAccess = ({ children, fallback }) => {
  const isNumberPortingEnabledForOrganization = useEnablements(NUMBER_PORTING)
  const [hasNoPortinLocationRestrictions] = useAccess(portinsNoLocationRestrictionsPermissions)
  const isCustomerHavingUSLocation = useCustomerLocation(US)
  const isCustomerHavingCALocation = useCustomerLocation(CA)

  return (
    <>
      {isNumberPortingEnabledForOrganization &&
        (hasNoPortinLocationRestrictions || isCustomerHavingUSLocation || isCustomerHavingCALocation) && (
          <PermissionsProtected requiredPermissions={viewPortinsPermissions} fallback={fallback}>
            {children}
          </PermissionsProtected>
        )}
    </>
  )
}

//The FuzeAdditionalViewAccess will be used to show any additional fields/values that needs to be displayed to users of a specific group.
//AS per the provided conditions the permissions identify if loggedin user is a fuze internal user, but this can be extended in future.
//portinsNoLocationRestrictions permission is given only to FUZE_LOGISTIC role
export const ViewAdditionalAttributesAccess = ({ children, fallback }) => {
  return (
    <>
      {
        <PermissionsProtected
          requiredPermissions={[permissions.portRequests.portinsNoLocationRestrictions]}
          permissionsCheck={hasSomePermission}
          fallback={fallback}
        >
          {children}
        </PermissionsProtected>
      }
    </>
  )
}

export const CreatePortinOrdersAccess = ({ children }) => {
  const isNumberPortingEnabledForOrganization = useEnablements(NUMBER_PORTING)
  const [hasNoPortinLocationRestrictions] = useAccess(portinsNoLocationRestrictionsPermissions)
  const isCustomerHavingUSLocation = useCustomerLocation(US)
  const isCustomerHavingCALocation = useCustomerLocation(CA)

  /*
   * hasNoPortinLocationRestrictions permission is used to replace the isLoggedInUserThinkingPhones check
   * The hasNoPortinLocationRestrictions allows users to perform following operations
   * 1. Can Create Number porting for any customer with or without a US Location.
   * 2. Can View Number porting of any customer with or without a US Location.
   * 3. Can Create Number porting & View number porting for a customer who does not have the Organization enabled.
   */
  return (
    <>
      {isNumberPortingEnabledForOrganization &&
        (hasNoPortinLocationRestrictions || isCustomerHavingUSLocation || isCustomerHavingCALocation) && (
          <PermissionsProtected requiredPermissions={createOrUpdatePortinsPermissions}>{children}</PermissionsProtected>
        )}
    </>
  )
}

export const UpdatePortinOrderAccess = ({ children, portinOrderId, fallback }) => {
  return (
    <>
      <PermissionsProtected requiredPermissions={createOrUpdatePortinsPermissions} fallback={fallback}>
        {children(buildUpdatePortinOrderPath(portinOrderId))}
      </PermissionsProtected>
    </>
  )
}

export const ManagePortinOrderAccess = ({ children, portinOrderId, fallback }) => {
  return (
    <PermissionsProtected requiredPermissions={managePortinsPermissions} fallback={fallback}>
      {children(buildManagePortinOrderPath(portinOrderId))}
    </PermissionsProtected>
  )
}

//Keeping this Access separate from update so that we can control the cancellation access based on a new permission if needed in future
//In current design, any user with createOrUpdate permission can cancel an order
const useCancelPortinOrderAccess = () => {
  const [hasPortinCancellationPermissions] = useAccess(createOrUpdatePortinsPermissions)
  const isNumberPortingEnabledForOrganization = useEnablements(NUMBER_PORTING)
  const [hasNoPortinLocationRestrictions] = useAccess(portinsNoLocationRestrictionsPermissions)
  const isCustomerHavingUSLocation = useCustomerLocation(US)
  const isCustomerHavingCALocation = useCustomerLocation(CA)
  const canUserCancelNumberPorts =
    isNumberPortingEnabledForOrganization &&
    (hasNoPortinLocationRestrictions || isCustomerHavingUSLocation || isCustomerHavingCALocation) &&
    hasPortinCancellationPermissions
  return [canUserCancelNumberPorts]
}
//Keeping this Access separate from update so that we can control the delete access based on a new permission if needed in future
//In current design, any user with createOrUpdate permission can delete an order
const useDeletePortinOrderAccess = () => {
  const [hasPortinCancellationPermissions] = useAccess(createOrUpdatePortinsPermissions)
  const isNumberPortingEnabledForOrganization = useEnablements(NUMBER_PORTING)
  const [hasNoPortinLocationRestrictions] = useAccess(portinsNoLocationRestrictionsPermissions)
  const isCustomerHavingUSLocation = useCustomerLocation(US)
  const isCustomerHavingCALocation = useCustomerLocation(CA)
  const canUserDeleteNumberPorts =
    isNumberPortingEnabledForOrganization &&
    (hasNoPortinLocationRestrictions || isCustomerHavingUSLocation || isCustomerHavingCALocation) &&
    hasPortinCancellationPermissions
  return [canUserDeleteNumberPorts]
}

//This access will identify if a user is either Fuze internal user or Customer admin and returns access based on the
//number of days till firm order commitment.
//A Fuze internal user can change the requested port date until 2 days before the firm order commitment
//where as a Customer admin can only change the requested port date until 1 day before the firm order commitment.
//Instead of maintaining this logic within in the component, we decided to calculate the same inside the access.
//*** FOC = Firm Order Commitment ( a date set by carrier when the port will happen) ***/
//This method will do all the heavy lifting to decide if a port date can be changed or not
//instead of having that logic scattered in multiple components
const useCanModifyRequestedPortDateAfterReceivingFOC = actualFocDate => {
  //hasPortinsSuppElevatedPermissions is only available to Fuze users
  const [hasPermissionToEditPortDateAsFuzeUser] = useAccess(portinCreateOrUpdateAsFuzeUser)
  //createOrUpdatePortinsPermissions are available for customer admins and Fuze internal users
  const [hasCreateOrUpdatePortinsPermissions] = useAccess(createOrUpdatePortinsPermissions)

  let canUserModifyRequestedPortDate = true
  //if foc date is not available then users are free to edit port date as many times
  if (actualFocDate) {
    const workingDaysTillFirmOrderCommitmentDate = getBusinessDaysInBetween(
      dateToEST(getMomentDateFromString(actualFocDate)),
      dateToEST(getCurrentDate())
    )
    const thresholdDaysForFuze = 1
    const thresholdDaysForCustomerAdmin = 1
    canUserModifyRequestedPortDate = hasPermissionToEditPortDateAsFuzeUser
      ? workingDaysTillFirmOrderCommitmentDate > thresholdDaysForFuze
      : hasCreateOrUpdatePortinsPermissions && workingDaysTillFirmOrderCommitmentDate > thresholdDaysForCustomerAdmin
  }

  return [canUserModifyRequestedPortDate, hasPermissionToEditPortDateAsFuzeUser]
}

export {
  usePortinAccess,
  useCancelPortinOrderAccess,
  useDeletePortinOrderAccess,
  useCanModifyRequestedPortDateAfterReceivingFOC
}
