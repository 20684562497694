import React, { Component } from "react"
import { searchOrganizationsPage } from "data/apis/foundry"
import { FormattedMessage } from "react-intl"
import { Button, Form, FormGroup } from "reactstrap"
import redirect from "lib/redirect"
import { buildHomePath } from "Dashboard/Access"
import { Spinner } from "reactstrap"
import { NoOrganisationFound, SearchOrganisationField } from "./SwitchOrganizationSearch"
import PartialHighlight from "components/PartialHighlight"
import { FixedSizeList } from "react-window"
import InfiniteLoader from "react-window-infinite-loader"
import { Alert } from "@fuze/react-fuzzy"

class SwitchOrganizationForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      maxRows: 1000,
      page: 0,
      organizations: [],
      query: ""
    }
  }

  async componentDidMount() {
    const organizations = await searchOrganizationsPage("", { offset: 0, limit: this.state.maxRows })

    this.setState({
      loading: false,
      organizations: organizations.data,
      totalRecords: organizations.pagination.total
    })
  }

  onQueryChange = queryValue => {
    this.setState({ query: queryValue }, async () => {
      const query = queryValue.toLowerCase()
      const result = await searchOrganizationsPage(query, { offset: 0, limit: this.state.maxRows })
      const filteredOrgs = result ? result.data : []
      const totalRecords = result.pagination.total

      this.setState({
        organizations: filteredOrgs,
        totalRecords,
        page: 0,
        isNextPageLoading: false
      })
    })
  }

  rowRenderer = ({
    index, // Index of row within collection
    style // Style object to be applied to row (to position it)
  }) => {
    const organization = this.state.organizations[index]
    const code = organization.code || (organization.organization && organization.organization.code)
    return (
      <div key={index} style={style} className={"list-item".concat(index % 2 ? " stripped" : "")}>
        <Button onClick={() => this.updateCustomer(code)} color={"link"}>
          <PartialHighlight value={organization.name} pattern={this.state.query} />
        </Button>
      </div>
    )
  }

  updateCustomer = async organizationCode => {
    const homePath = buildHomePath(`?organization=${organizationCode}`)
    redirect(homePath)
  }

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  loadMoreRows = () => {
    return this.state.isNextPageLoading
      ? null
      : new Promise(async resolve => {
          this.setState({ isNextPageLoading: true })
          const moreOrganizations = await searchOrganizationsPage(this.state.query, {
            offset: (this.state.page + 1) * this.state.maxRows,
            limit: this.state.maxRows
          })
          this.setState({
            organizations: [...this.state.organizations, ...moreOrganizations.data],
            isNextPageLoading: false,
            page: this.state.page + 1
          })
          resolve()
        })
  }

  // Every row is loaded except for our loading indicator row.
  isRowLoaded = ({ index }) => !!this.state.organizations[index]

  render() {
    const { loading, organizations, totalRecords } = this.state

    if (loading) {
      return <Spinner />
    } else {
      if (!!organizations && organizations.length > 0) {
        return (
          <Form noValidate>
            <SearchOrganisationField onQueryChange={query => this.onQueryChange(query)} />
            <FormGroup>
              <InfiniteLoader
                isItemLoaded={this.isRowLoaded}
                loadMoreItems={this.loadMoreRows}
                itemCount={totalRecords}
                minimumBatchSize={1000}
                threshold={150}
              >
                {({ onItemsRendered, ref }) => (
                  <FixedSizeList
                    width={400}
                    height={300}
                    ref={ref}
                    onItemsRendered={onItemsRendered}
                    itemCount={organizations.length}
                    itemSize={30}
                  >
                    {this.rowRenderer}
                  </FixedSizeList>
                )}
              </InfiniteLoader>
            </FormGroup>
          </Form>
        )
      } else if (!!organizations && organizations.length === 0) {
        return (
          <Form noValidate>
            <SearchOrganisationField onQueryChange={query => this.onQueryChange(query)} />
            <NoOrganisationFound query={this.state.query} />
          </Form>
        )
      } else {
        return (
          <Alert color="danger" className="text-center">
            <FormattedMessage id="desktopUpdates.searchOrganizations.messageErrorLoading" />
          </Alert>
        )
      }
    }
  }
}

export default SwitchOrganizationForm
