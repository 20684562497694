import React from "react"
import { Route } from "react-router-dom"
import { useHasPermissions } from "./usePermissions"
import { hasEveryPermission } from "lib/permissions"
import ErrorUnauthorized from "Errors/ErrorUnauthorized"

const PermissionsProtectedRoute = ({
  requiredPermissions,
  permissionsCheck = hasEveryPermission,
  render,
  ...props
}) => {
  const hasAccess = useHasPermissions(requiredPermissions)

  return (
    <Route
      {...props}
      render={props => {
        if (!hasAccess) {
          return <ErrorUnauthorized />
        }

        return render(props)
      }}
    />
  )
}

export default PermissionsProtectedRoute
