import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

export default function useSidebarSubmenu(section, matchingPath) {
  const page = useLocation()
  const [openedSubMenu, setOpenedSubMenu] = useState()
  const toggleSubMenu = subMenuToToggle => {
    if (subMenuToToggle === openedSubMenu) {
      setOpenedSubMenu("")
    } else {
      setOpenedSubMenu(subMenuToToggle)
    }
  }

  const matchingPathRegex = new RegExp(`^${matchingPath}`)
  const isCurrentPageInSubmenu = matchingPathRegex.test(page.pathname)

  useEffect(() => {
    if (isCurrentPageInSubmenu) {
      setOpenedSubMenu(section)
    }
  }, [isCurrentPageInSubmenu, section, page])

  return { openedSubMenu, toggleSubMenu, isCurrentPageInSubmenu }
}
