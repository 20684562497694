import React from "react"

import "./EmptyTableMessage.scss"

const EmptyTableMessage = props => {
  const { imageSource, title, children, classes } = props

  return (
    <div className={`empty-list ${classes ? classes : ""}`}>
      {imageSource ? <img src={imageSource} alt="" /> : <br />}
      <p>
        <strong>{title}</strong>
      </p>
      <p>{children}</p>
    </div>
  )
}

export default EmptyTableMessage
