import { useLocation } from "react-router"
import { migrationDashboardPath } from "AlloyMigration/Paths"
import { logisticsPath } from "DIDs/NumberRequests/Paths"

const nonHubPaths = [logisticsPath, migrationDashboardPath]

/**
 * Return true if the active path is not one of the toolbox paths
 */
const useIsHubActive = (): boolean => {
  const state = useLocation()
  return nonHubPaths.reduce<boolean>((prev, path) => prev && !new RegExp(path).test(state.pathname), true)
}

export default useIsHubActive
