import React from "react"
import AppErrorBoundary from "../Errors/AppErrorBoundary"
import useActingOrganization from "hooks/useActingOrganization"

import DIDRoutes from "DIDs/NumberRequests/Routes"
import PortRequestsRoutes from "DIDs/PortRequests/Routes"
import ToolboxRoutes from "DIDs/ToolboxRoutes"
import RateCenterRoutes from "../RateCenter/Routes"
import ErrorsRoutes from "Errors/Routes"

import "../styles/style.scss"
import PageViews from "lib/logging/PageViews"
import { Redirect, Route, Switch } from "react-router"

import VersionUpdater from "./VersionUpdater"
import { ActingOrganizationIsSuspended, isSuspended } from "../Errors/SuspendedOrganization"
import PageSpinner from "components/spinner/PageSpinner"
import "focus-visible"
import AuthenticationUnauthorized from "components/AuthenticationUnauthorized/AuthenticationUnauthorized"
import { ToastContainer } from "react-toastify"

function App() {
  const [organization, loading] = useActingOrganization()

  if (loading) {
    return <PageSpinner />
  }

  if (isSuspended(organization)) {
    return <ActingOrganizationIsSuspended />
  }

  return (
    <>
      <VersionUpdater />
      <ToastContainer containerId="toast-notifications-container" />
      <AppErrorBoundary>
        <Switch>
          <>
            <PageViews />
            <DIDRoutes />
            <PortRequestsRoutes />
            <RateCenterRoutes />
            <ToolboxRoutes />
            <ErrorsRoutes />
            <Route path="/">
              <Redirect to="/dids/requests"></Redirect>
            </Route>
          </>
        </Switch>
      </AppErrorBoundary>
      <AuthenticationUnauthorized />
    </>
  )
}

export default App
