import React, { Component } from "react"
import { Route, Redirect } from "react-router-dom"
//import { legalPath } from "../Legal/routes"
import { hasCustomerSignedAgreement, shouldRedirect } from "data/apis/foundry/agreements"

/**
 * This is a customization of react-route-dom Route that will redirect to the legal page
 * if the user belongs to a customer who hasn't signed the latest EULA (end user license agreement)
 */
class SignedAgreementProtectedRoute extends Component {
  state = {
    loading: true,
    hasCustomerSignedAgreement: false,
    shouldRedirect: false
  }

  componentDidMount() {
    hasCustomerSignedAgreement().then(hasCustomerSignedAgreement => {
      this.setState({ loading: false, hasCustomerSignedAgreement, shouldRedirect: shouldRedirect() })
    })
  }

  render() {
    const { render, ...rest } = this.props
    const { loading, hasCustomerSignedAgreement, shouldRedirect } = this.state

    return (
      <Route
        {...rest}
        render={props => {
          if (loading) {
            return null
          }

          if (!hasCustomerSignedAgreement && shouldRedirect) {
            return <Redirect to={"/legal"} />
          }

          return render(props)
        }}
      />
    )
  }
}

export default SignedAgreementProtectedRoute
