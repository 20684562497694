import React from "react"
import ExternalPathnames from "constants/external-pathnames"
import { FormattedMessage } from "react-intl"
import useConfiguration from "hooks/useConfiguration"
import { ToolboxAccess, useDidInvestigationsAccess } from "DIDs/ToolboxAccess"
import { useAlloyMigrationDashboardAccess } from "AlloyMigration/Access"
import useIsHubActive from "../hooks/useIsHubActive"
import classnames from "classnames"
import { Link } from "react-router-dom"
import { HomeAccess } from "Dashboard/Access"

const ExternalLinks = () => {
  const { portal } = useConfiguration()
  const isHubActive = useIsHubActive()
  const [hasDidsPermissions, didInvestigationsPath] = useDidInvestigationsAccess()
  const [, migrationDashboardPath] = useAlloyMigrationDashboardAccess()
  const defaultToolboxPath = hasDidsPermissions ? didInvestigationsPath : migrationDashboardPath

  return (
    <ul className={"d-flex m-0 list-unstyled"}>
      <HomeAccess>
        {path => (
          <li>
            <Link
              className={classnames({
                "header-navigation-button": !isHubActive,
                "header-navigation-button--active": isHubActive
              })}
              to={path}
              id="navigation-hub"
            >
              <FormattedMessage id="navigation.hub" />
            </Link>
          </li>
        )}
      </HomeAccess>
      <li>
        <a href={portal.url} className={"header-navigation-button"}>
          <FormattedMessage id="navigation.portal" />
        </a>
      </li>
      <li>
        <a href={ExternalPathnames.DATA_FUZE} className={"header-navigation-button"}>
          <FormattedMessage id="navigation.data" />
        </a>
      </li>
      <li>
        <a href={ExternalPathnames.STATUS_THINKINGPHONES} className={"header-navigation-button"}>
          <FormattedMessage id="navigation.status" />
        </a>
      </li>
      <ToolboxAccess>
        <li>
          <Link
            className={classnames({
              "header-navigation-button": isHubActive,
              "header-navigation-button--active": !isHubActive
            })}
            to={defaultToolboxPath}
            id="navigation-logistics"
          >
            <FormattedMessage id="navigation.logistics" />
          </Link>
        </li>
      </ToolboxAccess>
    </ul>
  )
}

export default ExternalLinks
