import React from "react"
import { Modal, ModalBody } from "@fuze/react-fuzzy"
import SwitchOrganizationForm from "./SwitchOrganizationForm"

import "./SwitchOrganizationModal.scss"

const SwitchOrganizationModal = ({ isOpen, toggle }) => {
  return (
    <Modal className="modal-switch-organization" isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        <SwitchOrganizationForm />
      </ModalBody>
    </Modal>
  )
}

export default SwitchOrganizationModal
