import React from "react"
import { getInitialsFromFullName } from "lib/string-utilities"
import useUserAvatar from "hooks/useUserAvatar"
import Avatar from "components/Avatar"

import "./Header.scss"

export function UserAvatar({ user, className }) {
  const avatar = useUserAvatar(user)
  const initials = getInitialsFromFullName(user?.fullName)
  return <Avatar classes={className} initials={initials} avatar={avatar} />
}
