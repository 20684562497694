import React, { lazy, Suspense } from "react"

import PermissionsAndSignedAgreementProtectedRoute from "components/PermissionsAndSignedAgreementProtectedRoute"
import PageSpinner from "components/spinner/PageSpinner"
import { renderHubLayout } from "components/layouts/HubLayout"
import {
  portOrdersPath,
  portOrdersReadyToSubmitPath,
  portOrdersInProgressPath,
  portOrdersArchivePath,
  newPortinOrderPath,
  updatePortinOrderPath,
  managePortinOrderPath
} from "./Paths"
import permissions from "constants/permissions"
import { useEnablements, NUMBER_PORTING } from "data/enablements"
import { useAccess } from "components/permissions/Access"
import { Switch } from "react-router-dom"
import { useCustomerLocation, US, CA } from "data/customer-location"

const portinOrders = lazy(() => import("./PortOrdersLanding"))
const newPortinOrders = lazy(() => import("./components/NewPortinOrders/NewPortinOrders"))
// Edit and view section managePortinOrder will be deprecated
const portOrder = lazy(() => import("./components/PortOrder/PortOrder"))

const createOrUpdatePortinsPermissions = [permissions.portRequests.createOrUpdatePortinOrders]
const viewPortinsPermissions = [permissions.portRequests.viewPortinOrders]
const managePortingPermissions = [
  permissions.portRequests.createOrUpdatePortinOrders,
  permissions.portRequests.viewPortinOrders
]
const portinsNoLocationRestrictionsPermissions = [permissions.portRequests.portinsNoLocationRestrictions]

const renderLoadingPage = Component => props => (
  <Suspense fallback={<PageSpinner />}>
    <Component {...props} />
  </Suspense>
)

const Routes = () => {
  const isNumberPortingEnabledForOrganization = useEnablements(NUMBER_PORTING)
  const [hasNoPortinLocationRestrictions] = useAccess(portinsNoLocationRestrictionsPermissions)
  const isCustomerHavingUSLocation = useCustomerLocation(US)
  const isCustomerHavingCALocation = useCustomerLocation(CA)

  return (
    <>
      <Switch>
        {isNumberPortingEnabledForOrganization &&
          (hasNoPortinLocationRestrictions || isCustomerHavingUSLocation || isCustomerHavingCALocation) && (
            <>
              <PermissionsAndSignedAgreementProtectedRoute
                requiredPermissions={viewPortinsPermissions}
                exact
                path={portOrdersPath}
                render={renderHubLayout(renderLoadingPage(portinOrders))}
              />
              <PermissionsAndSignedAgreementProtectedRoute
                requiredPermissions={viewPortinsPermissions}
                exact
                path={portOrdersReadyToSubmitPath}
                render={renderHubLayout(renderLoadingPage(portinOrders))}
              />
              <PermissionsAndSignedAgreementProtectedRoute
                requiredPermissions={viewPortinsPermissions}
                exact
                path={portOrdersInProgressPath}
                render={renderHubLayout(renderLoadingPage(portinOrders))}
              />
              <PermissionsAndSignedAgreementProtectedRoute
                requiredPermissions={viewPortinsPermissions}
                exact
                path={portOrdersArchivePath}
                render={renderHubLayout(renderLoadingPage(portinOrders))}
              />

              <PermissionsAndSignedAgreementProtectedRoute
                requiredPermissions={createOrUpdatePortinsPermissions}
                exact
                path={newPortinOrderPath}
                render={renderHubLayout(renderLoadingPage(newPortinOrders))}
              />
              <PermissionsAndSignedAgreementProtectedRoute
                requiredPermissions={createOrUpdatePortinsPermissions}
                exact
                path={updatePortinOrderPath}
                render={renderHubLayout(renderLoadingPage(portOrder))}
              />
              <PermissionsAndSignedAgreementProtectedRoute
                requiredPermissions={managePortingPermissions}
                exact
                path={managePortinOrderPath}
                render={renderHubLayout(renderLoadingPage(portOrder))}
              />
            </>
          )}
      </Switch>
    </>
  )
}

export default Routes
