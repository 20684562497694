import React from "react"
import permissions from "constants/permissions"
import { migrationDashboardPath, migrationStatusPath } from "./Paths"
import { buildPlanPath } from "../DIDs/ToolboxRoutes"
import { useAccess } from "components/permissions/Access"
import PermissionsProtected from "../components/permissions/PermissionsProtected"

export const useAlloyMigrationDashboardAccess = () =>
  useAccess([permissions.alloyMigration.migrationDashboard], migrationDashboardPath)

export const useAlloyMigrationStatusAccess = () =>
  useAccess([permissions.alloyMigration.migrationDashboard], migrationStatusPath)

export const AlloyMigrationDashboardAccess = ({ children }) => {
  return (
    <PermissionsProtected requiredPermissions={[permissions.alloyMigration.migrationDashboard]}>
      {children(migrationStatusPath)}
    </PermissionsProtected>
  )
}

export const AlloyMigrationStatusAccess = ({ children }) => {
  return (
    <PermissionsProtected requiredPermissions={[permissions.alloyMigration.migrationDashboard]}>
      {children(migrationStatusPath)}
    </PermissionsProtected>
  )
}

export const AlloyMigrationPlanAccess = ({ children }) => {
  return (
    <PermissionsProtected requiredPermissions={[permissions.alloyMigration.migrationPlan]}>
      {children(migrationStatusPath)}
    </PermissionsProtected>
  )
}

export const AlloyMigrationToolboxAccess = ({ children }) => {
  return (
    <PermissionsProtected requiredPermissions={[permissions.alloyMigration.migrationToolbox]}>
      {children}
    </PermissionsProtected>
  )
}

export const useAlloyMigrationPlanAccess = pathId =>
  useAccess([permissions.alloyMigration.migrationPlan], buildPlanPath(pathId))
