import _compact from "lodash/compact"
import _unescape from "lodash/unescape"
import _cloneDeep from "lodash/cloneDeep"
import { isEmpty } from "lodash"
import {
  PHONE_NUMBER_TYPE,
  SUBSCRIBER_TYPE,
  ALLOWED_ACTIONS_MENU_ITEMS_BY_STATUS,
  ACTION,
  ORDER_FIELD_MAPPING,
  SCHEDULE_TRANSFER_DATE_PROPS,
  PORTIN_ORDER_STATUS
} from "DIDs/PortRequests/data/constants"
import {
  dateToEST,
  getCurrentDate,
  getDaysInBetween,
  getFutureDate,
  getMomentDateFromString,
  setTime
} from "./calendarUtility"

// Extracting mapping data
const { SERVICE_ADDRESS, BILLING_ADDRESS, ACTIVATION } = ORDER_FIELD_MAPPING

const { minBusinessDays, maxDays } = SCHEDULE_TRANSFER_DATE_PROPS

/**
 * Check if a given status can activate port scheduling
 * @param {string} portOrderStatus Value of the order status. Eg. DRAFT, READY_FOR_SUBMISSION
 */
export const canSchedulePortOrder = portOrderStatus =>
  Boolean(
    portOrderStatus && ALLOWED_ACTIONS_MENU_ITEMS_BY_STATUS[portOrderStatus]?.includes(ACTION.SCHEDULE_ACTIVATION_DATE)
  ) ?? false

/*-------------------------
 * Data transform section
 -------------------------*/

/**
 *
 * Dev note:
 *
 * Need to transform data from server to formik and formik to server values.
 * Dealing with nested data causes more issues than it solves (Especially in
 * custom components and when re-using Fuze custom components).
 *
 * Notice, the use of _ to separate and denote nested values
 *
 */

/**
 * Helper function to generate address info
 *
 * @param {string} addressType Type of address billingAddress or service address
 * @param {{}} data Port order data
 *
 * @returns {{}}
 */
const getAddress = (addressType, data) => ({
  [`${addressType}_countryCode`]: { value: data?.[addressType]?.country },
  [`${addressType}_addressLine1`]: (data?.[addressType]?.streetNumber ?? "").concat(
    // If streetName and streetNumber is there, it returns (streetNumber + " " + streetName)
    // eg: "80 Clymer St" (streetNumber:80, streetName: Clymer St)
    // else if streetName is only there it returns streetName
    // else ""
    data?.[addressType]?.streetName
      ? data?.[addressType]?.streetNumber
        ? " " + data?.[addressType]?.streetName
        : data?.[addressType]?.streetName
      : ""
  ),
  [`${addressType}_addressline2`]: data?.[addressType]?.addressline2,
  [`${addressType}_streetNumber`]: data?.[addressType]?.streetNumber,
  [`${addressType}_streetName`]: data?.[addressType]?.streetName,
  [`${addressType}_city`]: data?.[addressType]?.city,
  [`${addressType}_state`]: { value: data?.[addressType]?.state },
  [`${addressType}_postalCode`]: data?.[addressType]?.zip,
  [`${addressType}_zip4`]: data?.[addressType]?.zip4
})

/**
 * Note: Exporting as one object so it is easy to re-export.
 */
export const portOrderTransformData = {
  /**
   * Transforms data from service to formik. Copying what is essential only.
   *
   * @param {{}} data Data sent from the service
   *
   * @returns {{}} formik values
   */
  toFormik: data => {
    const genericInfo = {
      // Order id
      externalOrderId: data?.externalOrderId,
      // Billing details
      billingTelephoneNumber: data?.btn,
      replacementBillingTelephoneNumber: data?.replacementBtn,
      // Ported numbers
      dids: data?.dids,
      // Oder status details
      orderStatus: data?.orderStatus,
      status: data?.status,
      // Helper details
      isTollFree: data?.phoneNumberType === PHONE_NUMBER_TYPE.TOLLFREE,
      isWired: data?.phoneNumberType === PHONE_NUMBER_TYPE.WIRED,
      isWireless: data?.phoneNumberType === PHONE_NUMBER_TYPE.WIRELESS,
      accountType: data?.accountInfo?.subscriberType || SUBSCRIBER_TYPE.BUSINESS,
      isFullPort: data?.partialPortin !== "true",
      canSchedulePortIntial: canSchedulePortOrder(data?.orderStatus ?? -1),
      portOrder: _cloneDeep(data)
    }

    // Account info
    const accountInfo = {
      accountInfo_loaAuthorizedPerson: data?.accountInfo?.loaAuthorizedPerson,
      accountInfo_loaAuthContactNumber: data?.accountInfo?.loaAuthContactNumber,
      accountInfo_accountNumber: data?.accountInfo?.accountNumber,
      accountInfo_accountPin: data?.accountInfo?.accountPin,
      // Business
      accountInfo_businessName: data?.accountInfo?.businessName,
      // Residential
      accountInfo_firstName: data?.accountInfo?.firstName,
      accountInfo_middleInitial: data?.accountInfo?.middleInitial,
      accountInfo_lastName: data?.accountInfo?.lastName
    }

    // Activation info
    const activationInfo = {
      isBillingAddressSameAsServiceAddress: data?.isBillingAddressSameAsServiceAddress,
      activation_actualFocDate: data?.activation?.actualFocDate,
      activation_suggestedDate: data?.activation.suggestedDate,
      activation_requestedDate: data?.activation.requestedDate,
      activation_expedite: JSON.parse(data?.activation.expedite || false)
    }

    // Address details
    const serviceAddressInfo = getAddress(SERVICE_ADDRESS, data)
    const billingAddressInfo = !data?.isBillingAddressSameAsServiceAddress && getAddress(BILLING_ADDRESS, data)

    return {
      ...genericInfo,
      ...accountInfo,
      ...activationInfo,
      ...serviceAddressInfo,
      ...billingAddressInfo
    }
  },

  /**
   * Transforms data from formik to service. Copying what is essential only.
   *
   * @param {{}} data Data sent from the service
   *
   * @returns {{}} service values
   */
  toService: formValues => {
    const {
      billingTelephoneNumber,
      replacementBillingTelephoneNumber,
      portOrder: { phoneNumberType },
      dids,
      accountInfo_loaAuthContactNumber,
      accountType,
      accountInfo_businessName,
      accountInfo_loaAuthorizedPerson,
      accountInfo_firstName,
      accountInfo_middleInitial,
      accountInfo_lastName,
      accountInfo_accountNumber,
      accountInfo_accountPin,
      serviceAddress_streetNumber,
      serviceAddress_streetName,
      serviceAddress_city,
      serviceAddress_state,
      serviceAddress_zip4,
      serviceAddress_countryCode,
      serviceAddress_addressLine1,
      serviceAddress_addressline2,
      isBillingAddressSameAsServiceAddress,
      serviceAddress_postalCode,
      billingAddress_streetNumber,
      billingAddress_streetName,
      billingAddress_city,
      billingAddress_state,
      billingAddress_zip4,
      billingAddress_countryCode,
      billingAddress_addressLine1,
      billingAddress_addressline2,
      billingAddress_postalCode,
      activation_requestedDate,
      activation_expedite,
      isFullPort,
      orderStatus
    } = formValues

    const updatedPostParams = {}

    updatedPostParams[ORDER_FIELD_MAPPING.BILLING_TELEPHONE_NUMBER.API_KEY_NAME] = billingTelephoneNumber

    // * 0. If toll free number don't send RBTN
    if (phoneNumberType && phoneNumberType !== PHONE_NUMBER_TYPE.TOLLFREE) {
      let newReplacementBillingTelephoneNumber = null

      /**
       * Note to Dev:
       * isPartialPort is used only if when creating a new portin order editing <NumberDetailsPanel /> when
       * in <ManagingPortinOrder />. Thus, need to make sure orderSupplementActive is checked as followed.
       *
       * i.e. : if !orderSupplementIsActive you are in either new/ update mode. If it is, you are performing
       * partial (section wise) updates.
       */

      if (dids?.includes(billingTelephoneNumber)) {
        newReplacementBillingTelephoneNumber = isFullPort ? "" : replacementBillingTelephoneNumber
      }

      updatedPostParams[
        ORDER_FIELD_MAPPING.REPLACEMENT_BILLING_TELEPHONE_NUMBER.API_KEY_NAME
      ] = newReplacementBillingTelephoneNumber
    }

    updatedPostParams[ORDER_FIELD_MAPPING.ACCOUNT_SECTION] = {
      loaAuthContactNumber: accountInfo_loaAuthContactNumber,
      subscriberType: accountType,
      businessName: accountType === SUBSCRIBER_TYPE.BUSINESS ? accountInfo_businessName : "",
      loaAuthorizedPerson: accountInfo_loaAuthorizedPerson,
      firstName: accountType === SUBSCRIBER_TYPE.RESIDENTIAL ? accountInfo_firstName : "",
      middleInitial: accountType === SUBSCRIBER_TYPE.RESIDENTIAL ? accountInfo_middleInitial : "",
      lastName: accountType === SUBSCRIBER_TYPE.RESIDENTIAL ? accountInfo_lastName : "",
      accountNumber: accountInfo_accountNumber,
      accountPin: accountInfo_accountPin
    }

    updatedPostParams[ORDER_FIELD_MAPPING.SERVICE_ADDRESS] = {
      streetNumber: serviceAddress_streetNumber,
      streetName: serviceAddress_streetName,
      city: serviceAddress_city,
      state: serviceAddress_state.value,
      zip4: serviceAddress_zip4,
      country: serviceAddress_countryCode?.value,
      addressLine1: serviceAddress_addressLine1,
      addressline2: serviceAddress_addressline2,
      zip: serviceAddress_postalCode
    }

    updatedPostParams[
      ORDER_FIELD_MAPPING.IS_BILLING_ADDRESS_SAME_AS_SERVICE_ADDRESS
    ] = isBillingAddressSameAsServiceAddress

    if (!isBillingAddressSameAsServiceAddress) {
      updatedPostParams[ORDER_FIELD_MAPPING.BILLING_ADDRESS] = {
        streetNumber: billingAddress_streetNumber,
        streetName: billingAddress_streetName,
        city: billingAddress_city,
        state: billingAddress_state?.value,
        zip4: billingAddress_zip4,
        country: billingAddress_countryCode?.value,
        addressLine1: billingAddress_addressLine1,
        addressline2: billingAddress_addressline2,
        zip: billingAddress_postalCode
      }
    }

    updatedPostParams[ORDER_FIELD_MAPPING.DIDS] = dids

    // Schedule port & expedite are sent as part of the payload only if order status is not in draft and pre submission status
    if (orderStatus !== PORTIN_ORDER_STATUS.DRAFT.statusCode && orderStatus !== PORTIN_ORDER_STATUS.PRE_SUBMIT_ERRORS) {
      updatedPostParams[ACTIVATION] = {
        expedite: activation_expedite
      }
      // Update schedule date only if expedite is false
      if (!activation_expedite) {
        updatedPostParams[ACTIVATION].requestedDate = activation_requestedDate
      }
    }

    return updatedPostParams
  }
}

/**
 * Validate whether all required fields are met
 * @param {{}} data formik values data
 */
export const isValidFormikValues = ({
  dids,
  isFullPort,
  billingTelephoneNumber,
  replacementBillingTelephoneNumber,
  accountInfo_loaAuthorizedPerson,
  accountInfo_loaAuthContactNumber,
  accountInfo_accountNumber,
  accountInfo_accountPin,
  accountInfo_businessName,
  accountInfo_firstName,
  accountInfo_lastName,
  serviceAddress_countryCode,
  serviceAddress_addressLine1,
  serviceAddress_city,
  serviceAddress_state,
  serviceAddress_postalCode,
  isBillingAddressSameAsServiceAddress,
  billingAddress_countryCode,
  billingAddress_addressLine1,
  billingAddress_city,
  billingAddress_state,
  billingAddress_postalCode,
  accountType,
  isTollFree,
  isWired
}) => {
  let isValid = false
  const isBillingTelephoneNumberValid = isTollFree ? true : !isEmpty(billingTelephoneNumber)

  const isReplacementBillingTelephoneNumberValid =
    isTollFree || isFullPort || !dids.includes(billingTelephoneNumber)
      ? true
      : !isEmpty(replacementBillingTelephoneNumber)

  const isLoaAuthContactNumberValid = !isTollFree ? true : !isEmpty(accountInfo_loaAuthContactNumber)
  const isAccountNumberValid = isTollFree || isWired ? true : !isEmpty(accountInfo_accountNumber)
  const isAccountPinValid = isTollFree || isWired ? true : !isEmpty(accountInfo_accountPin)

  let accountTypeSectionValid = true
  if (accountType === SUBSCRIBER_TYPE.BUSINESS) {
    accountTypeSectionValid = !isEmpty(accountInfo_businessName)
  } else {
    accountTypeSectionValid = !isEmpty(accountInfo_firstName) && !isEmpty(accountInfo_lastName)
  }

  const isServiceAddressSectionValid =
    !isEmpty(serviceAddress_countryCode?.value) &&
    !isEmpty(serviceAddress_addressLine1) &&
    !isEmpty(serviceAddress_city) &&
    !isEmpty(serviceAddress_state?.value) &&
    !isEmpty(serviceAddress_postalCode)

  let isBillingAddressSectionValid = true
  if (!isBillingAddressSameAsServiceAddress) {
    isBillingAddressSectionValid =
      !isEmpty(billingAddress_countryCode?.value) &&
      !isEmpty(billingAddress_addressLine1) &&
      !isEmpty(billingAddress_city) &&
      !isEmpty(billingAddress_state?.value) &&
      !isEmpty(billingAddress_postalCode)
  }

  isValid =
    !isEmpty(dids) &&
    isBillingTelephoneNumberValid &&
    isReplacementBillingTelephoneNumberValid &&
    !isEmpty(accountInfo_loaAuthorizedPerson) &&
    isLoaAuthContactNumberValid &&
    isAccountNumberValid &&
    isAccountPinValid &&
    accountTypeSectionValid &&
    isServiceAddressSectionValid &&
    isBillingAddressSectionValid

  return isValid
}

/**
 * A data transposing function to transpose from client -> server
 *
 * @param {{}} portOrder Original port data
 *
 * @returns {function} Returns a curried function
 */
export const setServerValues = portOrder => patchOrder => ({})

/*-------------------------
 * Schedule port section
 ------------------------*/

/**
 * Calculate the time of the schedule period
 *
 * @param {moment} date a moment date
 */
export const getPortOrderTimeWindow = date => {
  const currentEst = dateToEST(date)
  const minTime = setTime(currentEst.clone(), 8, 0, 0)
  const maxTime = setTime(currentEst, 20, 0, 0)

  return [minTime, maxTime]
}

/**
 * Calculate the dates of the port order schedule period.
 *
 * @param {string} suggestedOrRequestedDate Suggested or requested date
 * @param {[]} blackoutDates Suggested holidays
 */
export const getPortOrderDateWindow = (suggestDate, requestedDate, blackoutDates) => {
  const todayInEst = dateToEST(getCurrentDate())
  // Get the suggested date
  const suggestedDate = dateToEST(getMomentDateFromString(suggestDate))
  const minSlaDate = dateToEST(getFutureDate(todayInEst.clone(), minBusinessDays, { blackoutDates }))

  // Check the diff between the initial suggested date and min sla data
  let dateSuggested = getDaysInBetween(suggestedDate, minSlaDate) >= 0 ? suggestedDate?.clone() : minSlaDate?.clone()

  // If the suggested date time is startOfDay then we need to push it to
  // Working hours especially as minSLA is calculated with start of day
  if (dateSuggested.isSame(dateSuggested.clone().startOf("day"))) {
    setTime(dateSuggested, 8, 0, 0)
  }

  const minDate = dateSuggested?.clone()
  const maxDate = dateToEST(getFutureDate(todayInEst, maxDays, { excludeWeekends: false }))

  const selectedDate = dateToEST(getMomentDateFromString(requestedDate))

  return [selectedDate, minDate, maxDate]
}

/*------------------------------
 * Smart address section
 ------------------------------*/

/**
 * Converts a google location result into a formik values object
 * @param googleResults array - data from google's PlaceResult interface
 * @param values object - a formik values object to check for NA or international fields
 * @param addressType - string - type of the address can be either serviceAddress or billingAddress
 *
 * @returns an object
 */
export const mapFormFieldsFromGoogle = (googleResults, values, addressType) => {
  const countryFieldName = `${addressType}_countryCode`
  const stateFieldName = `${addressType}_state`
  const addressLine1 = `${addressType}_addressLine1`

  const addressComponents = googleResults.address_components
  const formFields = getFieldMapping(values, addressType)
  const fieldsMap = Object.entries(formFields).map(([field, data]) => {
    const fieldValues = data.types.map(
      type => addressComponents.find(result => result.types.includes(type))?.[data.nameType]
    )
    const value = data.combine ? fieldValues.join(" ").trim() : _compact(fieldValues)[0] || ""
    return [field, value]
  })

  // If the country is NA (uses short_name) then set it as an object for react-select
  const stateFieldType = formFields[stateFieldName].nameType === "short_name" && stateFieldName
  const setSelectFields = fieldsMap.map(([field, value]) =>
    [stateFieldType, countryFieldName].includes(field) ? [field, { value }] : [field, value]
  )
  const fields = Object.fromEntries(setSelectFields)

  // Use google's `adr_address` to get the proper address formatting
  fields[addressLine1] = getStreetAddressFromAdrAddress(googleResults.adr_address)

  return fields
}

// This can be reused from fuze if exported
export const formatSelectOptions = options => options.map(option => ({ value: option, label: option.label }))

export const isAddressValid = (values, addressType) => {
  const countryCode = `${addressType}_countryCode`
  const postalCode = `${addressType}_postalCode`
  const city = `${addressType}_city`
  const state = `${addressType}_state`
  const addressLine1 = `${addressType}_addressLine1`

  const fieldsNA = [postalCode, city, state, addressLine1, countryCode]
  const fieldsInternational = [city, addressLine1, countryCode]

  const fields = isCountryNA(values, countryCode) ? fieldsNA : fieldsInternational
  return fields.every(field => !!values[field])
}

export const getFieldMapping = (values, addressType) =>
  isCountryNA(values, `${addressType}_countryCode`)
    ? addressFormFieldsGoogleMap(addressType)
    : addressFormFieldsGoogleMapInternational(addressType)

export const isCountryNA = (values, countryFieldName) => ["US", "CA"].includes(values?.[countryFieldName]?.value)

// Dev note: If fuze code is exported no need to duplicate
// Google's `adr_address` field seems the only way to get the formatted address from their getDetails() api
// it's wrapped in an html string eg. '<span class="street-address">123 Some Address</span>...'
const getStreetAddressFromAdrAddress = str => {
  const address = str.split('<span class="street-address">').pop().split("</span>")
  return _unescape(address[0])
}

const addressFormFieldsGoogleMap = addressType => ({
  [`${addressType}_addressLine1`]: {
    types: ["street_number", "route"],
    nameType: "long_name",
    combine: true
  },
  [`${addressType}_addressline2`]: {
    types: ["subpremise"],
    nameType: "long_name"
  },
  [`${addressType}_streetNumber`]: {
    types: ["street_number"],
    nameType: "long_name"
  },
  [`${addressType}_streetName`]: {
    types: ["route"],
    nameType: "long_name"
  },
  [`${addressType}_city`]: {
    types: ["locality", "sublocality_level_1", "administrative_area_level_2", "administrative_area_level_3"],
    nameType: "long_name"
  },
  [`${addressType}_countryCode`]: {
    types: ["country"],
    nameType: "short_name"
  },
  [`${addressType}_state`]: {
    types: ["administrative_area_level_1"],
    nameType: "short_name"
  },
  [`${addressType}_postalCode`]: {
    types: ["postal_code"],
    nameType: "long_name"
  }
})

const addressFormFieldsGoogleMapInternational = addressType => ({
  ...addressFormFieldsGoogleMap(addressType),
  [`${addressType}_state`]: {
    types: ["administrative_area_level_1"],
    nameType: "long_name"
  }
})

// Gets an address from a formik values object
export const getAddressFromFormik = (values = {}, addressType) => {
  const addressLine1 = `${addressType}_addressLine1}`
  const addressLine2 = `${addressType}_addressline2`
  const city = `${addressType}_city`
  const state = `${addressType}_state`
  const postalCode = `${addressType}_postalCode`
  const countryCode = `${addressType}_countryCode`

  const fields = [addressLine1, addressLine2, city, state, postalCode, countryCode]
  const fieldsMap = fields.map(field => values[field]?.value || values[field])
  const formatString = values => _compact(values).join(" ").trim()

  const address = formatString(fieldsMap)
  const addressPart1 = formatString(fieldsMap.slice(0, 2))
  const addressPart2 = formatString(fieldsMap.slice(2))

  return [address, addressPart1, addressPart2]
}
