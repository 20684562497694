export const portOrdersPath = "/dids/port-orders"
export const newPortinOrderPath = "/dids/port-orders/new"
export const updatePortinOrderPath = "/dids/port-orders/:portinOrderId/edit"
export const managePortinOrderPath = "/dids/port-orders/:portinOrderId/manage"
//The logisticsPortinsPath is used to control the submenu expand/collapse. It has a small conflict with DID requests which has base path as logistics.
//TODO: resolve the conflict between NumberRequests and NumberPorting submenu where the NumberRequest submenu opens up when we access NumberPorting due to the base path /logistics common for both
export const logisticsPortinsPath = "/logistics/port-orders"
export const managePortinOrderByLogisticsPath = "/logistics/port-orders/:portinOrderId/manage"
export const bulkProvisionPath = "/provision/bulk"
export const managerFocChangeRequestsLogisticsPath = "/logistics/port-orders/foc-changes"
export const holidayPortalPath = "/logistics/port-orders/holiday-portal"

//Tabs routes for portOrdersPath
export const portOrdersReadyToSubmitPath = portOrdersPath + "/ready-to-submit"
export const portOrdersInProgressPath = portOrdersPath + "/in-progress"
export const portOrdersArchivePath = portOrdersPath + "/archive"
