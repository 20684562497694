import React, { lazy, Suspense } from "react"
import PermissionsAndSignedAgreementProtectedRoute from "components/PermissionsAndSignedAgreementProtectedRoute"
import PermissionsProtected from "components/permissions/PermissionsProtected"
import PageSpinner from "components/spinner/PageSpinner"

import { renderHubLayout } from "../components/layouts/HubLayout"
import permissions from "constants/permissions"

const renderLoadingPage = Component => props => (
  <Suspense fallback={<PageSpinner />}>
    <Component {...props} />
  </Suspense>
)

const ordersPath = "/orders"

const OrdersAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.orders.requiredPermissions]}>
    {children(ordersPath)}
  </PermissionsProtected>
)

export { OrdersAccess }
