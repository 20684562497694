import React, { lazy, Suspense } from "react"

import PermissionsAndSignedAgreementProtectedRoute from "components/PermissionsAndSignedAgreementProtectedRoute"
import permissions from "constants/permissions"
import PageSpinner from "components/spinner/PageSpinner"

import { renderToolboxLayout } from "components/layouts/ToolboxLayout"
import { allDidRequestsPath, didInvestigationsPath, emailDetailPath } from "./NumberRequests/Paths"
import {
  logisticsPortinsPath,
  managePortinOrderByLogisticsPath,
  holidayPortalPath,
  managerFocChangeRequestsLogisticsPath
} from "./PortRequests/Paths"
import { migrationDashboardPath, migrationStatusPath, migrationPlanPath } from "AlloyMigration/Paths"

const DidInvestigation = lazy(() => import("./NumberRequests/components/DidInvestigation"))
const DidRequests = lazy(() => import("./NumberRequests/DidRequests"))
const DidInvestigations = lazy(() => import("./NumberRequests/DidInvestigations"))
const PortinOrders = lazy(() => import("./PortRequests/PortinOrders"))
const ManagePortinOrder = lazy(() => import("./PortRequests/components/PortOrder/PortOrder"))
const FocChangeRequests = lazy(() => import("./PortRequests/FocChangeRequests"))
const portingHolidays = lazy(() => import("./PortRequests/Holidays"))

//A Logistics user should have updateDids to be able to see the Customer Dids menu on ToolboxSideBar
//used to control All number requests, Rate centers & DID failures on Toolbox sidebar
const didNumberRequestsAccessForLogistics = [permissions.dids.updateDids]
//A Logistics user should have updatePortinOrdersElevated to be able to see the Number porting menu on ToolboxSideBar
//Used to control All number ports & Foc change requests on left nav
const didNumberPortingAccessForLogistics = [permissions.portRequests.updatePortinOrdersElevated]
//The above permissions are assigned to a FUZE_LOGISTICS which is an add-on to FUZE_INTERNAL
//The FUZE_INTERNAL will automatically get the view permissions of the Number Requests and Number Porting data.
//Used to control Holiday Portal on left nav
const holidatPortalAccessForLogistics = [permissions.portRequests.updatePortinCalendar]

const buildPlanPath = orgCode => `/alloy/migration/plan/${orgCode}`

const renderLoadingPage = Component => props => (
  <Suspense fallback={<PageSpinner />}>
    <Component {...props} />
  </Suspense>
)

const renderGlobalView = Component => props => <Component {...props} globalView={true} />

const ToolboxRoutes = () => {
  return (
    <>
      <PermissionsAndSignedAgreementProtectedRoute
        requiredPermissions={didNumberRequestsAccessForLogistics}
        exact
        path={emailDetailPath}
        render={renderToolboxLayout(renderLoadingPage(DidInvestigation))}
      />
      <PermissionsAndSignedAgreementProtectedRoute
        requiredPermissions={didNumberRequestsAccessForLogistics}
        exact
        path={allDidRequestsPath}
        render={renderToolboxLayout(renderLoadingPage(renderGlobalView(DidRequests)))}
      />
      <PermissionsAndSignedAgreementProtectedRoute
        requiredPermissions={didNumberRequestsAccessForLogistics}
        exact
        path={didInvestigationsPath}
        render={renderToolboxLayout(renderLoadingPage(DidInvestigations))}
      />
      <PermissionsAndSignedAgreementProtectedRoute
        requiredPermissions={didNumberPortingAccessForLogistics}
        exact
        path={logisticsPortinsPath}
        render={renderToolboxLayout(renderLoadingPage(renderGlobalView(PortinOrders)))}
      />
      <PermissionsAndSignedAgreementProtectedRoute
        requiredPermissions={didNumberPortingAccessForLogistics}
        exact
        path={managePortinOrderByLogisticsPath}
        render={renderToolboxLayout(renderLoadingPage(ManagePortinOrder))}
      />
      <PermissionsAndSignedAgreementProtectedRoute
        requiredPermissions={didNumberPortingAccessForLogistics}
        exact
        path={managerFocChangeRequestsLogisticsPath}
        render={renderToolboxLayout(renderLoadingPage(renderGlobalView(FocChangeRequests)))}
      />
      <PermissionsAndSignedAgreementProtectedRoute
        requiredPermissions={holidatPortalAccessForLogistics}
        exact
        path={holidayPortalPath}
        render={renderToolboxLayout(renderLoadingPage(renderGlobalView(portingHolidays)))}
      />
    </>
  )
}
export { buildPlanPath }
export default ToolboxRoutes
