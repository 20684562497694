import React from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import { NavLink } from "react-router-dom"
import { useScrollPosition } from "hooks/globalHooks"
import useSidebarSubmenu from "hooks/useSidebarSubmenu"
import HubSidebarSubmenu from "./HubSidebarSubmenu"

import "./HubSidebar.scss"

import {
  useInvestigateDIDErrorsAccess,
  useViewAllDIDsAccess,
  useViewAllPortinOrdersAccess,
  useHolidayModuleAccess,
  useManageFocChangeRequestsAccess
} from "DIDs/ToolboxAccess"

import { useAlloyMigrationDashboardAccess, useAlloyMigrationStatusAccess } from "AlloyMigration/Access"
import { useRateCenterAccess } from "RateCenter/Access"
import { logisticsPath } from "DIDs/NumberRequests/Paths"
import { logisticsPortinsPath } from "DIDs/PortRequests/Paths"
import { AlloyMigrationToolboxAccess } from "../../../AlloyMigration/Access"

const logisticsSubmenuName = "logistics"
const portingSubmenuName = "porting"
const migrationSubmnuName = "migration"

const Logistics = () => {
  const [hasInvestigateDIDErrorsAccess, investigateDIDErrorsPath] = useInvestigateDIDErrorsAccess()
  const [hasViewAllDIDsAccess, viewAllDIDsAccessPath] = useViewAllDIDsAccess()
  const [hasRateCenterAccess, rateCenterPath] = useRateCenterAccess()

  const hasCustomerDidPermissions = hasInvestigateDIDErrorsAccess || hasViewAllDIDsAccess || hasRateCenterAccess

  const { openedSubMenu, toggleSubMenu, isCurrentPageInSubmenu } = useSidebarSubmenu(
    logisticsSubmenuName,
    logisticsPath
  )
  const isSubmenuOpen = openedSubMenu === logisticsSubmenuName
  return (
    <>
      {hasCustomerDidPermissions ? (
        <HubSidebarSubmenu
          isSubmenuOpen={isSubmenuOpen}
          onSubmenuToggle={toggleSubMenu}
          isActive={isCurrentPageInSubmenu}
          submenuName={logisticsSubmenuName}
          isLoaded={true}
          toggleButtonTranslation={"sidebar.customerDids"}
        >
          {hasInvestigateDIDErrorsAccess && (
            <NavLink
              id="investigate-did-errors"
              exact
              to={investigateDIDErrorsPath}
              tabIndex={isSubmenuOpen ? "0" : "-1"}
            >
              <span className="link-text">
                <FormattedMessage id="sidebar.didInvestigations" />
              </span>
            </NavLink>
          )}
          {hasViewAllDIDsAccess && (
            <NavLink id="view-all-dids" exact to={viewAllDIDsAccessPath} tabIndex={isSubmenuOpen ? "0" : "-1"}>
              <span className="link-text">
                <FormattedMessage id="sidebar.allDids" />
              </span>
            </NavLink>
          )}
          {hasRateCenterAccess && (
            <NavLink id="rateCenter" to={rateCenterPath} tabIndex={isSubmenuOpen ? "0" : "-1"}>
              <span className="link-text">
                <FormattedMessage id="sidebar.rateCenter" />
              </span>
            </NavLink>
          )}
        </HubSidebarSubmenu>
      ) : null}
    </>
  )
}

const LogisticsNumberPorting = () => {
  const [hasViewAllPortinOrdersAccess, viewAllPortinOrdersAccessPath] = useViewAllPortinOrdersAccess()
  const [hasHolidayModuleAccess, holidayPortalPath] = useHolidayModuleAccess()
  const [hasManageFocChangeRequestsAccess, managerFocChangeRequestsLogisticsPath] = useManageFocChangeRequestsAccess()

  const hasDidPortingPermissions =
    hasViewAllPortinOrdersAccess || hasHolidayModuleAccess || hasManageFocChangeRequestsAccess

  const { openedSubMenu, toggleSubMenu, isCurrentPageInSubmenu } = useSidebarSubmenu(
    portingSubmenuName,
    logisticsPortinsPath
  )
  const isSubmenuOpen = openedSubMenu === portingSubmenuName
  return (
    <>
      {hasDidPortingPermissions ? (
        <HubSidebarSubmenu
          isSubmenuOpen={isSubmenuOpen}
          onSubmenuToggle={toggleSubMenu}
          isActive={isCurrentPageInSubmenu}
          submenuName={portingSubmenuName}
          isLoaded={true}
          toggleButtonTranslation={"sidebar.customerNumberPorts"}
        >
          {hasViewAllPortinOrdersAccess && (
            <NavLink
              id="view-all-portin-orders"
              exact
              to={viewAllPortinOrdersAccessPath}
              tabIndex={isSubmenuOpen ? "0" : "-1"}
            >
              <span className="link-text">
                <FormattedMessage id="sidebar.allNumberPorts" />
              </span>
            </NavLink>
          )}
          {hasManageFocChangeRequestsAccess && (
            <NavLink
              id="foc-change-requests"
              exact
              to={managerFocChangeRequestsLogisticsPath}
              tabIndex={isSubmenuOpen ? "0" : "-1"}
            >
              <span className="link-text">
                <FormattedMessage id="sidebar.focChangeRequests" />
              </span>
            </NavLink>
          )}
          {hasHolidayModuleAccess && (
            <NavLink id="holiday-portal" exact to={holidayPortalPath} tabIndex={isSubmenuOpen ? "0" : "-1"}>
              <span className="link-text">
                <FormattedMessage id="sidebar.holidayPortal" />
              </span>
            </NavLink>
          )}
        </HubSidebarSubmenu>
      ) : null}
    </>
  )
}

const AlloyMigration = () => {
  const [hasViewAlloyMigrationDashboard, alloyMigrationDashboardPath] = useAlloyMigrationDashboardAccess()
  const [hasAlloyMigrationStatusAccess, alloyMigrationStatusPath] = useAlloyMigrationStatusAccess()

  const { openedSubMenu, toggleSubMenu, isCurrentPageInSubmenu } = useSidebarSubmenu(
    migrationSubmnuName,
    alloyMigrationDashboardPath
  )
  const isSubmenuOpen = openedSubMenu === migrationSubmnuName
  return (
    <HubSidebarSubmenu
      isSubmenuOpen={isSubmenuOpen}
      onSubmenuToggle={toggleSubMenu}
      isActive={isCurrentPageInSubmenu}
      submenuName={migrationSubmnuName}
      isLoaded={true}
      toggleButtonTranslation={"sidebar.alloyMigration"}
    >
      {hasViewAlloyMigrationDashboard && (
        <NavLink
          id="alloy-migration-dashboard"
          exact
          to={alloyMigrationDashboardPath}
          tabIndex={isSubmenuOpen ? "0" : "-1"}
        >
          <span className="link-text">
            <FormattedMessage id="sidebar.migrationDashboard" />
          </span>
        </NavLink>
      )}
      {hasAlloyMigrationStatusAccess && (
        <NavLink
          id="alloy-migration-status"
          exact
          to={alloyMigrationStatusPath}
          tabIndex={isSubmenuOpen ? "0" : "-1"}
          isActive={(match, location) => {
            // show Migration Status as "active" in sidebar when viewing a plan
            if (location?.pathname?.includes("/alloy/migration/plan/") || match?.isExact) {
              return true
            }
            if (!match) {
              return false
            }
          }}
        >
          <span className="link-text">
            <FormattedMessage id="sidebar.migrationStatus" />
          </span>
        </NavLink>
      )}
    </HubSidebarSubmenu>
  )
}

const ToolboxSidebar = () => {
  const scrollYPosition = useScrollPosition()

  return (
    <ul id="sidebar-hub" className={scrollYPosition > 70 ? "fixed" : ""}>
      <Logistics />
      <LogisticsNumberPorting />
      <AlloyMigrationToolboxAccess>
        <AlloyMigration />
      </AlloyMigrationToolboxAccess>
    </ul>
  )
}

export default injectIntl(ToolboxSidebar)
