import { useState, useEffect } from "react"

import { getOrganization as getActingOrganization } from "lib/user-session"
import { getOrganization as getOrganizationDetails } from "data/apis/foundry"

export default function useActingOrganization() {
  const [organization, setOrganization] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function getOrganization() {
      const organization = await getOrganizationDetails(getActingOrganization())
      setOrganization(organization)
      setLoading(false)
    }
    getOrganization()
  }, [])

  return [organization, loading]
}
