import React from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import { NavLink } from "react-router-dom"
import { HomeAccess } from "Dashboard/Access"
import {
  useDevicesHomepageAccess,
  useActivateDeviceAccess,
  useClaimDevicesAccess,
  usePurchasePhonesAccess
} from "Devices/Access"
import { useUtilityPhonesListAccess } from "Utility/Access"
import { OrdersAccess } from "Orders/Routes"
import { LocationsAccess } from "Locations/Routes"
import {
  useDesktopUpdatesAccess,
  useGuestsPolicyAccess,
  useEmailSettingsAccess,
  useIdentityManagementAccess,
  useContactRostersAccess,
  useCompanySettingsAccess,
  useActOnListAccess
} from "CompanySettings/Access"
import HubSidebarSubmenu, { SidebarSubmenuItem } from "./HubSidebarSubmenu"
import { useDIDsAccess } from "DIDs/NumberRequests/Access"
import { usePortinAccess } from "DIDs/PortRequests/Access"
import { useLineAccess } from "Lines/Access"
import { useApiKeyManagementAccess } from "Integrations/Access"
import {
  useContactRostersHomepageAccess,
  useUsersHomepageAccess,
  useAdministratorsListAccess
} from "ContactRosters/Access"
import { TroubleshootingAccess } from "Troubleshooting/Access"
import { useEnablements, CONTACT_ROSTERS } from "data/enablements"
import { canLoggedInThinkingphonesUserAccess } from "lib/user-session"

import "./HubSidebar.scss"

import useSidebarSubmenu from "hooks/useSidebarSubmenu"
import useIsSkuOnContract from "hooks/useIsSkuOnMsaContract"

import { ALLOY_UTILITY_SKU } from "constants/skus"
import { userPagesHomePath } from "users/Routes"
import { useIntl } from "react-intl"

import { ErrorBadgeWithTooltip } from "../../ErrorBadge/ErrorBadgeWithTooltip"
import { useContactCenterChatQueuesAccess } from "../../../ContactCenter/Access"
import { CONTACT_CENTER_CHAT } from "data/enablements"

const Home = () => (
  <HomeAccess>
    {path => (
      <li>
        <NavLink id="dashboard" to={path}>
          <i className="side-bar__icon icon-dashboard" />
          <span className="side-bar__label">
            <FormattedMessage id="sidebar.home" />
          </span>
        </NavLink>
      </li>
    )}
  </HomeAccess>
)

const Locations = () => {
  const intl = useIntl()

  return (
    <LocationsAccess>
      {path => (
        <li>
          <NavLink id="locations" to={path}>
            <i className="side-bar__icon icon-locations" />
            <span className="side-bar__label">
              <FormattedMessage id="sidebar.locations" />
            </span>
          </NavLink>
        </li>
      )}
    </LocationsAccess>
  )
}

const Users = () => {
  const [hasContactRostersHomePagePermissions, contactRostersHomepagePath] = useContactRostersHomepageAccess()
  const [hasUsersHomePagePermissions, usersHomepagePath] = useUsersHomepageAccess()
  const [hasAdministratorPermissions, administratorsListPath] = useAdministratorsListAccess()
  const isActiveOrganizationEnabledForContactRosters = useEnablements(CONTACT_ROSTERS)
  const usersSectionName = "users"

  const { openedSubMenu, toggleSubMenu, isCurrentPageInSubmenu } = useSidebarSubmenu(
    usersSectionName,
    userPagesHomePath
  )
  const isSubmenuOpen = openedSubMenu === usersSectionName

  if (!hasUsersHomePagePermissions && !hasAdministratorPermissions) {
    return null
  }

  return (
    <HubSidebarSubmenu
      isSubmenuOpen={isSubmenuOpen}
      onSubmenuToggle={toggleSubMenu}
      isActive={isCurrentPageInSubmenu}
      submenuName={usersSectionName}
      isLoaded={true}
      toggleButtonTranslation={"sidebar.usersSubNav.button"}
    >
      {hasUsersHomePagePermissions && (
        <NavLink id="users" exact to={usersHomepagePath} tabIndex={isSubmenuOpen ? "0" : "-1"}>
          <span className="link-text">
            <FormattedMessage id="sidebar.usersSubNav.allUsers" />
          </span>
        </NavLink>
      )}
      {hasAdministratorPermissions && (
        <NavLink id="administrators" exact to={administratorsListPath} tabIndex={isSubmenuOpen ? "0" : "-1"}>
          <span className="link-text">
            <FormattedMessage id="sidebar.usersSubNav.administrators" />
          </span>
        </NavLink>
      )}
      {hasContactRostersHomePagePermissions && isActiveOrganizationEnabledForContactRosters && (
        <NavLink id="contact-rosters" exact to={contactRostersHomepagePath} tabIndex={isSubmenuOpen ? "0" : "-1"}>
          <span className="link-text">
            <FormattedMessage id="sidebar.usersSubNav.contactRosters" />
          </span>
        </NavLink>
      )}
    </HubSidebarSubmenu>
  )
}

const Devices = () => {
  const [hasUtilitySku, isSkuLoaded] = useIsSkuOnContract(ALLOY_UTILITY_SKU)
  const [hasDeviceHomePagePermissions, devicesHomepagePath] = useDevicesHomepageAccess()
  const [hasUtilityPhonesListPermissions, utilityPhonesPath] = useUtilityPhonesListAccess()
  const [hasActivateDevicePermissions, activateDevicePath] = useActivateDeviceAccess()
  const [hasClaimDevicesPermissions, claimDevicesPath] = useClaimDevicesAccess()
  const [hasPurchasePhonesPermissions, purchasePhonesPath] = usePurchasePhonesAccess()

  const devicesSectionName = "devices"
  const { openedSubMenu, toggleSubMenu, isCurrentPageInSubmenu } = useSidebarSubmenu(
    devicesSectionName,
    devicesHomepagePath
  )

  const isSubmenuOpen = openedSubMenu === devicesSectionName

  if (
    !hasDeviceHomePagePermissions &&
    !hasUtilityPhonesListPermissions &&
    !hasActivateDevicePermissions &&
    !hasClaimDevicesPermissions &&
    !hasPurchasePhonesPermissions
  ) {
    return null
  }

  return (
    <HubSidebarSubmenu
      isSubmenuOpen={isSubmenuOpen}
      onSubmenuToggle={toggleSubMenu}
      isActive={isCurrentPageInSubmenu}
      submenuName={"devices"}
      isLoaded={isSkuLoaded}
      toggleButtonTranslation={"sidebar.devices.button"}
    >
      {hasDeviceHomePagePermissions && (
        <NavLink id="devices-home" exact to={devicesHomepagePath} tabIndex={isSubmenuOpen ? "0" : "-1"}>
          <span className="link-text">
            <FormattedMessage id="sidebar.devices.home" />
          </span>
        </NavLink>
      )}
      {hasUtilitySku && hasUtilityPhonesListPermissions && (
        <NavLink id="utility-phones" to={utilityPhonesPath} tabIndex={isSubmenuOpen ? "0" : "-1"}>
          <span className="link-text">
            <FormattedMessage id="sidebar.devices.utilityPhones" />
          </span>
        </NavLink>
      )}
      {hasActivateDevicePermissions && (
        <NavLink id="bulk-activation" to={activateDevicePath} tabIndex={isSubmenuOpen ? "0" : "-1"}>
          <span className="link-text">
            <FormattedMessage id="sidebar.devices.bulk" />
          </span>
        </NavLink>
      )}
      {hasClaimDevicesPermissions && (
        <NavLink id="register-phones" to={claimDevicesPath} tabIndex={isSubmenuOpen ? "0" : "-1"}>
          <span className="link-text">
            <FormattedMessage id="sidebar.devices.register" />
          </span>
        </NavLink>
      )}
      {hasPurchasePhonesPermissions && (
        <NavLink id="purchase-phones" to={purchasePhonesPath} tabIndex={isSubmenuOpen ? "0" : "-1"}>
          <span className="link-text">
            <FormattedMessage id="sidebar.devices.purchase" />
          </span>
        </NavLink>
      )}
    </HubSidebarSubmenu>
  )
}

const Orders = () => (
  <OrdersAccess>
    {path => (
      <li>
        <NavLink id="orders" to={path}>
          <i className="side-bar__icon icon-billing-activity" />
          <span className="side-bar__label">
            <FormattedMessage id="sidebar.orders" />
          </span>
        </NavLink>
      </li>
    )}
  </OrdersAccess>
)

const CompanySettings = () => {
  const companySettingsSectionName = "company-settings"

  const [hasCompanySettingsAccess] = useCompanySettingsAccess()
  const [hasDesktopUpdatesAccess, desktopUpdatesPath] = useDesktopUpdatesAccess()
  const [hasGuestsPolicyAccess, guestsPolicyPath] = useGuestsPolicyAccess()
  const [hasEmailSettingsAccess, emailSettingsPath] = useEmailSettingsAccess()
  const [hasIdentityManagementAccess, identityManagementPath] = useIdentityManagementAccess()
  const [hasContactRostersAccess, contactRostersPath] = useContactRostersAccess()
  const [hasActOnListAccess, actOnListPath] = useActOnListAccess()

  const { openedSubMenu, toggleSubMenu, isCurrentPageInSubmenu } = useSidebarSubmenu(
    companySettingsSectionName,
    "/settings" // TODO Store these somewhere
  )
  const isSubmenuOpen = openedSubMenu === companySettingsSectionName

  if (!hasCompanySettingsAccess) {
    return null
  }

  return (
    <HubSidebarSubmenu
      isSubmenuOpen={isSubmenuOpen}
      onSubmenuToggle={toggleSubMenu}
      isActive={isCurrentPageInSubmenu}
      submenuName={companySettingsSectionName}
      isLoaded={true}
      toggleButtonTranslation={"sidebar.companySettings"}
    >
      {hasDesktopUpdatesAccess && (
        <SidebarSubmenuItem id="desktop-updates" to={desktopUpdatesPath} isSubmenuOpen={isSubmenuOpen}>
          <FormattedMessage id="sidebar.desktop" />
        </SidebarSubmenuItem>
      )}
      {hasGuestsPolicyAccess && (
        <SidebarSubmenuItem id="guests-policy" to={guestsPolicyPath} isSubmenuOpen={isSubmenuOpen}>
          <FormattedMessage id="sidebar.guests" />
        </SidebarSubmenuItem>
      )}
      {hasEmailSettingsAccess && (
        <SidebarSubmenuItem id="email-settings" to={emailSettingsPath} isSubmenuOpen={isSubmenuOpen}>
          <FormattedMessage id="sidebar.emailSettings" />
        </SidebarSubmenuItem>
      )}
      {hasIdentityManagementAccess && (
        <SidebarSubmenuItem id="identity-management" to={identityManagementPath} isSubmenuOpen={isSubmenuOpen}>
          <FormattedMessage id="sidebar.identityManagement" />
        </SidebarSubmenuItem>
      )}
      {hasContactRostersAccess && (
        <SidebarSubmenuItem id="company-settings-contact-rosters" to={contactRostersPath} isSubmenuOpen={isSubmenuOpen}>
          <FormattedMessage id="sidebar.contactRosters" />
        </SidebarSubmenuItem>
      )}
      {hasActOnListAccess && (
        <SidebarSubmenuItem id="company-settings-act-on-list" to={actOnListPath} isSubmenuOpen={isSubmenuOpen}>
          <FormattedMessage id="sidebar.actOnList" />
        </SidebarSubmenuItem>
      )}
    </HubSidebarSubmenu>
  )
}

const Phones = () => {
  const phoneSectionName = "phone-system"
  const { openedSubMenu, toggleSubMenu, isCurrentPageInSubmenu } = useSidebarSubmenu(phoneSectionName, "/dids")

  const isSubmenuOpen = openedSubMenu === phoneSectionName

  const [hasActivateALinePermissions, activateALinePath] = useLineAccess()
  const [hasDidsPermissions, didRequestsPath] = useDIDsAccess()
  const [hasPortRequestsPermissions, portRequestsPath] = usePortinAccess()

  if (!hasDidsPermissions && !hasActivateALinePermissions && !hasPortRequestsPermissions) {
    return null
  }

  return (
    <HubSidebarSubmenu
      isSubmenuOpen={isSubmenuOpen}
      onSubmenuToggle={toggleSubMenu}
      isActive={isCurrentPageInSubmenu}
      submenuName={"phone-system"}
      isLoaded={true}
      toggleButtonTranslation={"sidebar.phoneSystem"}
    >
      {hasDidsPermissions && (
        <NavLink id="dids" to={didRequestsPath} tabIndex={isSubmenuOpen ? "0" : "-1"}>
          <span className="link-text">
            <FormattedMessage id="sidebar.dids" />
          </span>
        </NavLink>
      )}
      {hasPortRequestsPermissions && (
        <NavLink id="portin" to={portRequestsPath} tabIndex={isSubmenuOpen ? "0" : "-1"}>
          <span className="link-text">
            <FormattedMessage id="sidebar.portin" />
          </span>
        </NavLink>
      )}
      {hasActivateALinePermissions && (
        <NavLink id="activate-a-line" to={activateALinePath} tabIndex={isSubmenuOpen ? "0" : "-1"}>
          <span className="link-text">
            <FormattedMessage id="sidebar.companyLines" />
          </span>
        </NavLink>
      )}
    </HubSidebarSubmenu>
  )
}

const Integrations = () => {
  const integrations = "integrations"
  const { openedSubMenu, toggleSubMenu, isCurrentPageInSubmenu } = useSidebarSubmenu(integrations, "/integrations")

  const isSubmenuOpen = openedSubMenu === integrations

  const [hasApiKeyManagementPermissions, apiKeyManagementPath] = useApiKeyManagementAccess()

  if (!hasApiKeyManagementPermissions) {
    return null
  }

  return (
    <HubSidebarSubmenu
      isSubmenuOpen={isSubmenuOpen}
      onSubmenuToggle={toggleSubMenu}
      isActive={isCurrentPageInSubmenu}
      submenuName={"integrations"}
      isLoaded={true}
      toggleButtonTranslation={"sidebar.integrations"}
    >
      {hasApiKeyManagementPermissions && (
        <NavLink id="apiKeyManagement" to={apiKeyManagementPath} tabIndex={isSubmenuOpen ? "0" : "-1"}>
          <span className="link-text">
            <FormattedMessage id="sidebar.apiKeyManagement" />
          </span>
        </NavLink>
      )}
    </HubSidebarSubmenu>
  )
}

const Troubleshooting = () => {
  if (!canLoggedInThinkingphonesUserAccess()) {
    return null
  }
  return (
    <TroubleshootingAccess>
      {path => (
        <li>
          <NavLink id="troubleshooting" to={path}>
            <i className="side-bar__icon icons icon-star_stroke" />
            <span className="side-bar__label">
              <FormattedMessage id="sidebar.troubleshooting" />
            </span>
          </NavLink>
        </li>
      )}
    </TroubleshootingAccess>
  )
}

const ContactCenter = () => {
  const contactCenterSectionName = "contact-center"
  const isContactCenterChatEnabled = useEnablements(CONTACT_CENTER_CHAT)
  const [hasChatQueuesAccess, chatQueuesPath] = useContactCenterChatQueuesAccess()

  const { openedSubMenu, toggleSubMenu, isCurrentPageInSubmenu } = useSidebarSubmenu(
    contactCenterSectionName,
    "/contact-center"
  )
  const isSubmenuOpen = openedSubMenu === contactCenterSectionName

  if (!isContactCenterChatEnabled || !hasChatQueuesAccess) {
    return null
  }

  return (
    <HubSidebarSubmenu
      isSubmenuOpen={isSubmenuOpen}
      onSubmenuToggle={toggleSubMenu}
      isActive={isCurrentPageInSubmenu}
      submenuName={contactCenterSectionName}
      isLoaded={true}
      toggleButtonTranslation={"sidebar.contactCenter.button"}
    >
      <SidebarSubmenuItem id="contact-center-chat-queues" to={chatQueuesPath} isSubmenuOpen={isSubmenuOpen}>
        <FormattedMessage id="sidebar.contactCenter.chatQueues" />
      </SidebarSubmenuItem>
    </HubSidebarSubmenu>
  )
}

const HubSidebar = () => {
  return (
    <ul id="sidebar-hub">
      <Home />
      <Locations />
      <Users />
      <Devices />
      <Orders />
      <Phones />
      <ContactCenter />
      <Integrations />
      <Troubleshooting />
      <CompanySettings />
    </ul>
  )
}

export default injectIntl(HubSidebar)
