import React from "react"
import permissions from "constants/permissions"
import Access, { useAccess } from "components/permissions/Access"

import { activateALinePath } from "./Paths"

const useLineAccess = () => useAccess([permissions.lines.activate], activateALinePath)

const LineAccess = ({ children }) => {
  const [hasPermissions, path] = useLineAccess()
  return <Access hasPermissions={hasPermissions} path={path} children={children} />
}

export { LineAccess, useLineAccess }
