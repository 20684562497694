import React from "react"
import PermissionsProtected from "components/permissions/PermissionsProtected"
import Access, { useAccess } from "components/permissions/Access"
import {
  desktopUpdatesPath,
  guestPolicyPath,
  activeGuestsPath,
  deactivatedGuestsPath,
  buildGuestsActionPath,
  notificationPolicyPath,
  identityManagementPath,
  rostersVisibilityPath,
  actOnListVisibilityPath
} from "./Paths"
import permissions from "constants/permissions"
import { ACT_ON_LIST, CONTACT_ROSTERS, useEnablements } from "data/enablements"

export const useCompanySettingsAccess = () =>
  useAccess([permissions.desktopUpdates.companySettingsAccess], desktopUpdatesPath)
export const useDesktopUpdatesAccess = () =>
  useAccess([permissions.desktopUpdates.manageDesktopVersion], desktopUpdatesPath)
export const useIdentityManagementAccess = () =>
  useAccess([permissions.desktopUpdates.companySettingsAccess], identityManagementPath)
export const useEmailSettingsAccess = () =>
  useAccess([permissions.emailSettings.customerWelcomeEmailUpdate], notificationPolicyPath)
export const useGuestsPolicyAccess = () => useAccess([permissions.guests.enableFeature], guestPolicyPath)
export const useContactRostersAccess = () => {
  const [hasAccess, path] = useAccess([permissions.contactRosters.companyVisibilityAccess], rostersVisibilityPath)
  const isEnabled = useEnablements(CONTACT_ROSTERS)

  return [hasAccess && isEnabled, path]
}

export const ActiveGuestsAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.guests.manage]}>
    {children(activeGuestsPath)}
  </PermissionsProtected>
)

export const DeactivatedGuestsAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.guests.manage]}>
    {children(deactivatedGuestsPath)}
  </PermissionsProtected>
)

export const GuestsActionAccess = ({ children, guestId, actionId }) => (
  <PermissionsProtected requiredPermissions={[permissions.guests.manage]}>
    {children(buildGuestsActionPath(guestId, actionId))}
  </PermissionsProtected>
)

export const GuestsPolicyAccess = ({ children, fallback }) => {
  const [hasPermissions, path] = useGuestsPolicyAccess()
  return <Access hasPermissions={hasPermissions} path={path} children={children} />
}

export function useActiveGuestsPath() {
  return useAccess([permissions.guests.manage], activeGuestsPath)
}

export const useActOnListAccess = () => {
  const [hasAccess, path] = useAccess(
    [permissions.tenantManagement.readOwnActOnList, permissions.tenantManagement.readAnyActOnList],
    actOnListVisibilityPath
  )
  const isEnabled = useEnablements(ACT_ON_LIST)

  return [hasAccess && isEnabled, path]
  // retaining for debugging until we have an enabled tenant
  // return [hasAccess, path]
}

export const ActOnListModifyAccess = ({ children, fallback = <></> }) => {
  const [hasPermissions, path] = useAccess([permissions.tenantManagement.editAnyActOnList], actOnListVisibilityPath)
  return <Access hasPermissions={hasPermissions} path={path} children={children} />
}
