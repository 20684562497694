import React from "react"
import { Button, Navbar, NavbarBrand, NavbarToggler } from "reactstrap"
import { Link } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"
import { HomeAccess } from "../../../Dashboard/Access"
import { useToggle } from "react-use"
import ExternalLinks from "./components/ExternalLinks"
import UserMenu from "./components/UserMenu"
import ExternalPathnames from "../../../constants/external-pathnames"
import TenantSwitcher from "./components/TenantSwitcher"
import { changeFocusToElementWithId } from "lib/a11y-utilities"

const TopNavBar: React.FunctionComponent = () => {
  const intl = useIntl()
  const [isOpen, toggleOpen] = useToggle(false)

  return (
    <>
      <span className="skip-link-wrapper">
        <Button
          id="skip-link"
          tag={Link}
          color="primary"
          size="sm"
          outline
          to="#main-content"
          onClick={() => changeFocusToElementWithId("main-content")}
        >
          <FormattedMessage id="header.skipLink" />
        </Button>
      </span>

      <Navbar
        color="faded"
        light
        id="header"
        className="navbar-expand-md"
        aria-label={intl.formatMessage({ id: "header.ariaLabel" })}
      >
        <NavbarToggler onClick={toggleOpen} />
        <div className={"top-nav-bar"}>
          <div className={"top-nav-bar--home"}>
            <HomeAccess>{path => <NavbarBrand href={path} />}</HomeAccess>
          </div>
          <div className={"top-nav-bar--tenant-switcher"}>
            <TenantSwitcher allowCustomerSwitching={true} />
          </div>
          <div className="top-nav-bar--links">
            <ExternalLinks />
          </div>
          <UserMenu isOpen={isOpen} />
          <div className={"top-nav-bar--user-help"}>
            <a href={ExternalPathnames.SUPPORT} target="_blank" rel="noopener noreferrer">
              <i className="icon-hubnav_help top-nav-bar--user-help--icon" />
            </a>
          </div>
        </div>
      </Navbar>
    </>
  )
}

export default TopNavBar
