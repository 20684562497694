export const COMMON = Object.freeze({
  //all reusable common properties should be catergorized and added here.
  LENGTH_PROPS: {
    CUSTOMER_NAME_MAX_LENGTH: 60,
    COMPANY_NAME_MAX_LENGTH: 60,
    LOA_AUTH_PERSON_NAME_MAX_LENGTH: 60,
    STREET_NAME_MAX_LENGTH: 60,
    STREET_NUMBER_MAX_LENGTH: 10,
    CITY_MAX_LENGTH: 32,
    NUMBER_PREFIX_MAX_LENGTH: 6,
    NUMBER_SUFFIX_MAX_LENGTH: 45,
    STREET_SUFFIX_MAX_LENGTH: 45,
    ADDRESS_LINE_2_MAX_LENGTH: 200
  },
  THRESHOLD_DAYS_FOR_FUZE: 1,
  THRESHOLD_DAYS_FOR_CUSTOMER_ADMIN: 1
})

export const PORTIN_TABLE_PROPS = Object.freeze({
  ROWS_PER_PAGE: 50,
  PAGINATION_SIZE: 6
})

export const PORTIN_ORDER_HISTORY_TABLE_PROPS = Object.freeze({
  ROWS_PER_PAGE: 10,
  PAGINATION_SIZE: 6
})

export const TIME_CONSTANTS = Object.freeze({
  COUNT_REFRESH_INTERVAL: 60000
})

export const PORTIN_ORDER_TABLE_COLUMN = Object.freeze({
  DATE_CREATED: "O_D",
  ORDER_ID_FIELD: "O_I",
  REQUEST_ID_FIELD: "R_I",
  CARRIER_FIELD: "C_D_N",
  PROVISIONED_QTY_FIELD: "R_Q",
  STATUS_FIELD: "O_S",
  TRANSFER_TYPE: "T_T",
  MODIFIED_DATE: "M_D",
  PORT_DATE: "P_D",
  ORGANIZATION_NAME: "O_N"
})

export const PHONE_NUMBER_TYPE = Object.freeze({
  WIRELESS: "wireless",
  WIRED: "wired",
  TOLLFREE: "tollfree"
})

export const SUBSCRIBER_TYPE = Object.freeze({
  BUSINESS: "BUSINESS",
  RESIDENTIAL: "RESIDENTIAL"
})

export const PORTIN_ORDER_STATUS_VALUES = Object.freeze({
  DRAFT: "draft",
  PRE_SUBMIT_ERRORS: "submission_failures",
  READY_FOR_SUBMISSION: "ready_for_submission",
  SUBMITTED: "submitted",
  REQUESTED_SUPP: "supp",
  FOC: "foc",
  COMPLETED: "completed",
  EXCEPTION: "exception",
  REQUESTED_CANCEL: "requested_cancel",
  CANCELLED: "cancelled"
})

export const PORTIN_ORDER_STATUS_CATEGORIES = Object.freeze({
  DRAFT: "draft",
  PRE_SUBMIT_ERRORS: "submission_failures",
  READY_FOR_SUBMISSION: "ready_for_submission",
  SUBMITTED: "submitted",
  REQUESTED_SUPP: "supp",
  FOC: "foc",
  COMPLETED: "completed",
  EXCEPTION: "exception",
  REQUESTED_CANCEL: "requested_cancel",
  CANCELLED: "cancelled"
})

export const PORTIN_ORDER_STATUS = Object.freeze({
  DRAFT: {
    statusCode: 0,
    category: PORTIN_ORDER_STATUS_CATEGORIES.DRAFT,
    value: PORTIN_ORDER_STATUS_VALUES.DRAFT
  },
  PRE_SUBMIT_ERRORS: {
    statusCode: 2,
    category: PORTIN_ORDER_STATUS_CATEGORIES.PRE_SUBMIT_ERRORS,
    value: PORTIN_ORDER_STATUS_VALUES.PRE_SUBMIT_ERRORS
  },
  SUBMITTED: {
    statusCode: 3,
    category: PORTIN_ORDER_STATUS_CATEGORIES.SUBMITTED,
    value: PORTIN_ORDER_STATUS_VALUES.SUBMITTED
  },
  EXCEPTION: {
    statusCode: 4,
    category: PORTIN_ORDER_STATUS_CATEGORIES.EXCEPTION,
    value: PORTIN_ORDER_STATUS_VALUES.EXCEPTION
  },
  REQUESTED_CANCEL: {
    statusCode: 5,
    category: PORTIN_ORDER_STATUS_CATEGORIES.REQUESTED_CANCEL,
    value: PORTIN_ORDER_STATUS_VALUES.REQUESTED_CANCEL
  },
  REQUESTED_SUPP: {
    statusCode: 6,
    category: PORTIN_ORDER_STATUS_CATEGORIES.REQUESTED_SUPP,
    value: PORTIN_ORDER_STATUS_VALUES.REQUESTED_SUPP
  },
  FOC: {
    statusCode: 7,
    category: PORTIN_ORDER_STATUS_CATEGORIES.FOC,
    value: PORTIN_ORDER_STATUS_VALUES.FOC
  },
  CANCELLED: {
    statusCode: 8,
    category: PORTIN_ORDER_STATUS_CATEGORIES.CANCELLED,
    value: PORTIN_ORDER_STATUS_VALUES.CANCELLED
  },
  COMPLETED: {
    statusCode: 9,
    category: PORTIN_ORDER_STATUS_CATEGORIES.COMPLETED,
    value: PORTIN_ORDER_STATUS_VALUES.COMPLETED
  },
  READY_FOR_SUBMISSION: {
    statusCode: 11,
    category: PORTIN_ORDER_STATUS_CATEGORIES.READY_FOR_SUBMISSION,
    value: PORTIN_ORDER_STATUS_VALUES.READY_FOR_SUBMISSION
  }
})

export const TRANSFER_TYPE = Object.freeze({
  ON_DEMAND: "numberPorting.table.transferType.ondemand",
  AUTOMATIC: "numberPorting.table.transferType.automatic"
})

export const PORTIN = Object.freeze({
  TEXT_AREA_ROWS: 6,
  TEXT_AREA_COLS: 160
})

export const QUESTIONNAIRE_OPTIONS = Object.freeze({
  YES: "Yes",
  NO: "No",
  ERROR: "error",
  SAMPLE_LOA_FILENAME: "fuze_sample_loa_and_respOrg_templates.zip"
})

const yesIsValid = value => value === QUESTIONNAIRE_OPTIONS.YES
const noIsValid = value => value === QUESTIONNAIRE_OPTIONS.NO

export const QUESTIONNAIRE = Object.freeze([
  {
    LABEL: "numberPorting.getStarted.amIReady.question0.label",
    DESC: "numberPorting.getStarted.amIReady.question0.description",
    TYPE: "error",
    ADDITIONAL_MSG: "numberPorting.getStarted.amIReady.question0.additionalMessage",
    update: (question, value) => ({ ...question, value, valid: yesIsValid(value) }),
    value: undefined,
    valid: false
  },
  {
    LABEL: "numberPorting.getStarted.amIReady.question1.label",
    DESC: "numberPorting.getStarted.amIReady.question1.description",
    TYPE: "error",
    ADDITIONAL_MSG: "numberPorting.getStarted.amIReady.question1.additionalMessage",
    update: (question, value) => ({ ...question, value, valid: noIsValid(value) }),
    value: undefined,
    valid: false
  },
  {
    LABEL: "numberPorting.getStarted.amIReady.question2.label",
    DESC: "numberPorting.getStarted.amIReady.question2.description",
    TYPE: "warning",
    ADDITIONAL_MSG: "numberPorting.getStarted.amIReady.question2.additionalMessage",
    update: (question, value) => ({ ...question, value, valid: noIsValid(value) }),
    value: undefined,
    valid: false
  },
  {
    LABEL: "numberPorting.getStarted.amIReady.question3.label",
    DESC: "numberPorting.getStarted.amIReady.question3.description",
    TYPE: "warning",
    ADDITIONAL_MSG: "numberPorting.getStarted.amIReady.question3.additionalMessage",
    update: (question, value) => ({ ...question, value, valid: yesIsValid(value) }),
    value: undefined,
    valid: false
  },
  {
    LABEL: "numberPorting.getStarted.amIReady.question4.label",
    DESC: "numberPorting.getStarted.amIReady.question4.description",
    TYPE: "warning",
    ADDITIONAL_MSG: "numberPorting.getStarted.amIReady.question4.additionalMessage",
    update: (question, value) => ({ ...question, value, valid: yesIsValid(value) }),
    value: undefined,
    valid: false
  },
  {
    LABEL: "numberPorting.getStarted.amIReady.question5.label",
    DESC: "numberPorting.getStarted.amIReady.question5.description",
    TYPE: "error",
    ADDITIONAL_MSG: "numberPorting.getStarted.amIReady.question5.additionalMessage",
    update: (question, value) => ({ ...question, value, valid: yesIsValid(value) }),
    SHOW_LOA_DOWNLOAD_OPTION: true,
    value: undefined,
    valid: false
  },
  {
    LABEL: "numberPorting.getStarted.amIReady.question6.label",
    DESC: "numberPorting.getStarted.amIReady.question6.description",
    TYPE: "warning",
    ADDITIONAL_MSG: "numberPorting.getStarted.amIReady.question6.additionalMessage",
    update: (question, value) => ({ ...question, value, valid: noIsValid(value) }),
    value: undefined,
    valid: false
  }
])

export const DATE_FORMATS = Object.freeze({
  FULL: "dddd MMM DD, YYYY hh:mm A ZZ",
  WITH_SHORT_TIME: "MMM DD, YYYY [at] hh:mm a",
  WITH_SHORT_TIME_EST: "MMM DD, YYYY [at] hh:mm a [ET]",
  WITH_SHORT_TIME_FORMAT: "MMM DD, YYYY [at] hh:mm A",
  WITH_SHORT_TIME_FORMAT_EST: "MMM DD, YYYY [at] hh:mm A [ET]",
  WITH_SHORT_TIME_ALT: "MMM DD, YYYY hh:mm a",
  WITH_SHORT_TIME_ALT_EST: "MMM DD, YYYY hh:mm a [ET]",
  SHORT_DATE: "MMM DD, YYYY",
  SHORT_DATE_EST: "MMM DD, YYYY [ET]",
  PORTIN_DATE_PICKER_DATE_FORMAT: "MM/DD/YY h:mm a",
  PORTIN_DATE_PICKER_SHORT_DATE_FORMAT: "MM/DD/YYYY",
  PORTIN_DATE_PICKER_DATE_FORMAT_EST: "MM/DD/YY h:mm a [ET]",
  PORTIN_DATE_PICKER_TIME_FORMAT: "HH:mm",
  WITH_SHORT_DATE_EST: "MMM DD [@] hh:mm a [EST]"
})

export const ORDER_FIELD_MAPPING = Object.freeze({
  DIDS: "dids",
  CREATED_DATE: "createdDate",
  CREATED_BY: "requestedUserFullName",
  MODIFIED_BY: "modifiedUserFullName",
  RATE_CENTERS: "rateCenters",
  REQUESTED_QTY_FIELD: "requestedQuantity",
  MODIFIED_DATE: "modifiedDate",
  EXTERNAL_ORDER_ID: "externalOrderId",
  ORGANIZATION_NAME: "organizationName",
  EXTERNAL_REQUEST_ID: "portinExternalRequestId",
  BILLING_TELEPHONE_NUMBER: {
    FIELD_NAME: "billingTelephoneNumber",
    API_KEY_NAME: "btn"
  },
  REPLACEMENT_BILLING_TELEPHONE_NUMBER: {
    FIELD_NAME: "replacementBillingTelephoneNumber",
    API_KEY_NAME: "replacementBtn"
  },
  ORIGINAL_PORT_ORDER: "portOrder",
  WIRELESS_CARRIER: "wirelessCarrier",
  ORDER_ID: "orderId",
  COMPANY_NAME: "businessName",
  CUSTOMER_FIRSTNAME: "firstName",
  /**
   * @deprecated
   * @use MIDDLE_NAME
   */
  CUSTOMER_MIDNAME: "middleInitial",
  MIDDLE_NAME: "middleInitial",
  /**
   * @deprecated
   * @use LAST_NAME
   */
  CUSTOMER_LASTNAME: "lastName",
  LAST_NAME: "lastName",
  CONTACT_NUMBER_OF_LOA_AUTH_PERSON: "loaAuthContactNumber",
  PERSON_AUTH: "loaAuthorizedPerson",
  ACCOUNT_NUMBER: "accountNumber",
  ACCOUNT_PIN: "accountPin",
  STREET_NUMBER: "streetNumber",
  STREET_NAME: "streetName",
  /**
   * @deprecated
   * @use ADDRESS_LINE2
   */
  ADDRESS: "addressline2",
  ADDRESS_LINE_1: "addressLine1",
  ADDRESS_LINE_2: "addressline2",
  CITY: "city",
  STATE: "state",
  COUNTRY: "country",
  COUNTRY_CODE: "countryCode",
  ZIPCODE: "zip",
  POSTAL_CODE: "postalCode",
  ZIPPLUS: "zip4",
  NUMBERPREFIX: "numberPrefix",
  NUMBERSUFFIX: "numberSuffix",
  PREDIRECTIONAL: "preDir",
  POSTDIRECTIONAL: "postDir",
  STREETSUFFIX: "streetSuffix",
  SUBSCRIBER_TYPE: "subscriberType",
  /**
   * @deprecated
   * @use ACCOUNT_TYPE
   */
  ACCOUNT_TYPE_NAME: "accountType",
  PORT_TYPE: "partialPortin",
  BUSINESS_INPUT: "business-input",
  RESIDENTIAL_INPUT: "residential-input",
  SERVICE_ADDRESS: "serviceAddress",
  BILLING_ADDRESS: "billingAddress",
  /**
   * @deprecated
   * @use ACCOUNT_INFO
   */
  ACCOUNT_SECTION: "accountInfo",
  NUMBER_UPDATE: "telephoneNumberUpdate",
  END_USER_UPDATE: "endUserUpdate",
  SERVICE_ADDRESS_UPDATE: "serviceAddressUpdate",
  SCHEDULE_TRANSFER_UPDATE: "scheduleTransferUpdate",
  ACTIVATION: "activation",
  ACTUAL_FOC_DATE: "actualFocDate",
  SUGGESTED_ACTIVATION_DATE: "suggestedDate",
  REQUESTED_ACTIVATION_DATE: "requestedDate",
  /**
   * @deprecated
   * @use REQUESTED_ACTIVATION_DATE with ACTIVATION
   */
  REQ_ACTIVATION_DATE_FIELD: "activation.requestedDate",
  NUMBER_VIEW_UPDATE: "numberViewUpdate",
  ORDER_STATUS: "orderStatus",
  ORDER_STATUS_VALUE: "status",
  FILE: "file",
  EXPEDITE: "expedite",
  SMART_ADDRESS: "smartAddress",
  ACCOUNT_TYPE: "accountType",
  ACCOUNT_INFO: "accountInfo",
  IS_TOLL_FREE: "isTollFree",
  IS_WIRED: "isWired",
  IS_WIRELESS: "isWireless",
  IS_FULL_PORT: "isFullPort",
  CAN_SCHEDULE_PORT: "canSchedulePortIntial",
  IS_BILLING_ADDRESS_SAME_AS_SERVICE_ADDRESS: "isBillingAddressSameAsServiceAddress",
  PHONE_TYPE: "phoneNumberType"
})

/**
 * This mapping is handy for API sync/async errors mapping
 * Added empty string as a field name for unused error fields
 * Can add appropriate formik fields in future if required
 */
export const API_ERROR_FIELD_MAPPING = Object.freeze({
  BILLING_TELEPHONE_NUMBER: "billingTelephoneNumber",
  DIDS: "dids",
  ACCOUNT_PIN: "accountInfo_accountPin",
  ACCOUNT_NUMBER: "accountInfo_accountNumber",
  PERSON_AUTH: "accountInfo_loaAuthorizedPerson",
  LOA_AUTH_CONTACT_NUMBER: "accountInfo_loaAuthContactNumber",
  REQ_ACTIVATION_DATE_FIELD: "activation_requestedDate",
  SUBSCRIBER_TYPE: "accountType",
  CUSTOMER_MIDNAME: "accountInfo_middleInitial",
  CUSTOMER_FIRSTNAME: "accountInfo_firstName",
  CUSTOMER_LASTNAME: "accountInfo_lastName",
  REPLACEMENT_BILLING_TELEPHONE_NUMBER: "replacementBillingTelephoneNumber",
  FILE: "",
  PHONE_NUMBER: "",
  ORDER_ID: "",
  SIGNED_DATE: "",
  FILE_ID: "",
  TARGET_ORDER_STATUS: "",
  EXPEDITE: "",
  COMPANY_NAME: "accountInfo_businessName",

  //Billing Address seection
  COUNTRY: "serviceAddress_countryCode",
  // Street number and name related errors will be displayed
  // along with smart address field
  STREET_NUMBER: "serviceAddress_addressLine1",
  STREET_NAME: "serviceAddress_addressLine1",
  ADDRESSLINE2: "serviceAddress_addressline2",
  CITY: "serviceAddress_city",
  STATE: "serviceAddress_state",
  ZIPCODE: "serviceAddress_postalCode",
  ZIP4: "serviceAddress_zip4",

  //Billing Address section
  BILLING_ADDRESS_COUNTRY: "billingAddress_countryCode",
  // Street number and name related errors will be displayed
  // along with smart address field
  BILLING_ADDRESS_STREET_NUMBER: "billingAddress_addressLine1",
  BILLING_ADDRESS_STREET_NAME: "billingAddress_addressLine1",
  BILLING_ADDRESS_ADDRESSLINE2: "billingAddress_addressline2",
  BILLING_ADDRESS_CITY: "billingAddress_city",
  BILLING_ADDRESS_STATE: "billingAddress_state",
  BILLING_ADDRESS_ZIPCODE: "billingAddress_postalCode",
  BILLING_ADDRESS_ZIP4: "billingAddress_zip4",

  BILLING_ADDRESS: "",
  CUSTOMER_NAME: "",
  ACTUAL_FOC_DATE: "",
  NUMBER_PREFIX: "",
  NUMBER_SUFFIX: "",
  STREET_SUFFIX: "",
  PRE_DIRECTION: "",
  POST_DIRECTION: "",
  HOLIDAY_FILE: "",
  EMAIL: "",
  ORGANIZATION: "",
  HOLIDAY_ID: "",
  DOCUMENT_TYPE: "",
  VERSION: "",
  ACCOUNT_TYPE_NAME: "subscriberType"
})

export const PORTIN_TABLE_SORTABLE_FIELDS = Object.freeze({
  O_I: "externalOrderId",
  O_D: "createdDate",
  R_I: "portinExternalRequestId",
  C_D_N: "rateCenters",
  R_Q: "requestedQuantity",
  M_D: "modifiedDate",
  O_S: "orderStatus",
  O_N: "organizationName",
  P_D: "activation"
})

export const SUPPORTING_DOCUMENT_TYPES = Object.freeze([
  {
    DISPLAY_NAME: "Letter of authorization (LOA)",
    API_NAME: "Letter of Authorization",
    API_VALUE: 0
  },
  {
    DISPLAY_NAME: "Copy of bill (COB)",
    API_NAME: "Copy of Bill",
    API_VALUE: 1
  },
  {
    DISPLAY_NAME: "Customer service record (CSR)",
    API_NAME: "Customer Service Record",
    API_VALUE: 2
  }
])

export const SUPPORTING_DOCUMENTS_TABLE_COLUMN = Object.freeze({
  SUPPORTING_DOCUMENT_TYPE: "fileTypeId",
  SUPPORTING_DOCUMENT_DATE_UPDATED: "createdDate",
  SUPPORTING_DOCUMENT_NAME: "fileName",
  SUPPORTING_DOCUMENT_SIGNED_DATE: "signedDate"
})

export const PORTIN_RESPONSE_VALUES = Object.freeze({
  OK: "ok"
})

export const PORTIN_PROCESSING_STATUS = Object.freeze({
  OPEN: "open",
  PROCESSING: "processing",
  COMPLETED: "completed",
  FAILED: "failed",
  SUBMIT: "Submit",
  DONE: "Done"
})

export const PHONE_NUMBER_ICON = Object.freeze({
  wired: "icon-phone",
  wireless: "icon-mobile",
  tollfree: "icon-phone-calling"
})

export const SCHEDULE_TRANSFER_DATE_PROPS = Object.freeze({
  minBusinessDays: 5,
  maxDays: 31
})

export const SERVICE_ADDRESS_DIRECTIONS = Object.freeze([
  { value: "N", text: "North" },
  { value: "S", text: "South" },
  { value: "E", text: "East" },
  { value: "W", text: "West" },
  { value: "NE", text: "North East" },
  { value: "NW", text: "North West" },
  { value: "SE", text: "South East" },
  { value: "SW", text: "South West" }
])

export const PORTIN_ORDER_COMMITMENT_CONSTANTS = Object.freeze({
  MAX_TIMES_FOC_CHANGE_IS_ALLOWED: 3,
  FUZE_CONCERN_PARTY: "Carrier",
  CUSTOMER_ADMIN_CONCERN_PARTY: "Fuze"
})

export const VALIDATION_TEXT = Object.freeze({
  REQUIRED: "numberPorting.form.validation.required",
  INVALID_FORMAT: "numberPorting.form.validation.invalidFormat",
  TEN_DIGIT_NUMBER_ONLY: "numberPorting.form.validation.only10DigitsAllowed",
  INVALID_REPLACEMENT_TELEPHONE_NUMBER: "numberPorting.form.validation.invalidReplacementBillingTelephoneNumber"
})

// Validation messages for port order form
export const VALIDATION_MESSAGES = Object.freeze({
  BASE_PATH: "numberPorting.portOrder.form.validation.errors",
  REQUIRED: "numberPorting.portOrder.form.validation.errors.required",
  INVALID_FORMAT: "numberPorting.portOrder.form.validation.errors.invalidFormat"
})

export const BULK_PHONE_NUMBERS_FILE_TEMPLATE = Object.freeze({
  SAMPLE_PHONE_NUMBERS_DATA_WITH_HEADER: [
    "Phone numbers",
    "6783542435",
    "3038425678",
    "(303)812-5898",
    "8003542671",
    "+1303.852.5678"
  ],
  SAMPLE_CSV_FILE_NAME: "Sample phone number template.csv"
})

export const PORTIN_HOLIDAYS_FILE_TEMPLATE = Object.freeze({
  SAMPLE_HOLIDAY_DATA_WITH_HEADER: [
    "01/01/2021,New Year's day",
    "02/07/2021,President's day",
    "05/25/2021,Memorial Day"
  ],
  SAMPLE_CSV_FILE_NAME: "Sample holiday template.csv"
})

export const BULK_PHONE_NUMBERS_FILE_UPLOAD = Object.freeze({
  CSV_EXTENSION: "csv",
  CSV_HEADERS: ["Phone numbers"]
})

export const BULK_PHONE_NUMBERS_FILE_UPLOAD_ERROR = Object.freeze({
  ParsingError: "parsingError",
  InvalidExtension: "invalidExtensionError",
  InvalidFileFormat: "invalidFileFormat",
  Unknown: "unknownError",
  csvFileEmpty: "emptyCsvFile"
})

export const CHECKPORTABILITY_OPTIONS = Object.freeze({
  CSV_ENTRY: "csvNumbers",
  MANUAL_ENTRY: "manualNumbers"
})

/**
 * Actions dropdown, NBA acton definitions
 */
export const ACTION = Object.freeze({
  UPDATE: "update",
  VIEW: "view",
  CANCEL: "cancel",
  SCHEDULE_ACTIVATION_DATE: "scheduleActivationDate",
  SUBMIT: "submit",
  RESOLVE: "resolve",
  DELETE: "delete",
  ADDITIONAL_RECIPIENTS: "additionalRecipients",
  VIEW_ORDER_HISTORY: "viewOrderHistory",
  PROVISION_NUMBERS: "provisionNumbers"
})

/**
 * Selectable bulk actions by status
 */
export const ALLOWED_BULK_ACTIONS_MENU_ITEMS_BY_STATUS = Object.freeze({
  [PORTIN_ORDER_STATUS.DRAFT.statusCode]: [ACTION.DELETE, ACTION.ADDITIONAL_RECIPIENTS],
  [PORTIN_ORDER_STATUS.PRE_SUBMIT_ERRORS.statusCode]: [ACTION.CANCEL, ACTION.ADDITIONAL_RECIPIENTS],
  [PORTIN_ORDER_STATUS.READY_FOR_SUBMISSION.statusCode]: [
    ACTION.DELETE,
    ACTION.SUBMIT,
    ACTION.SCHEDULE_ACTIVATION_DATE,
    ACTION.ADDITIONAL_RECIPIENTS
  ],
  [PORTIN_ORDER_STATUS.SUBMITTED.statusCode]: [
    ACTION.CANCEL,
    ACTION.ADDITIONAL_RECIPIENTS,
    ACTION.SCHEDULE_ACTIVATION_DATE
  ],
  [PORTIN_ORDER_STATUS.EXCEPTION.statusCode]: [
    ACTION.CANCEL,
    ACTION.SCHEDULE_ACTIVATION_DATE,
    ACTION.ADDITIONAL_RECIPIENTS
  ],
  [PORTIN_ORDER_STATUS.REQUESTED_CANCEL.statusCode]: [ACTION.ADDITIONAL_RECIPIENTS],
  [PORTIN_ORDER_STATUS.REQUESTED_SUPP.statusCode]: [
    ACTION.CANCEL,
    ACTION.SCHEDULE_ACTIVATION_DATE,
    ACTION.ADDITIONAL_RECIPIENTS
  ],
  [PORTIN_ORDER_STATUS.FOC.statusCode]: [ACTION.CANCEL, ACTION.ADDITIONAL_RECIPIENTS]
})

/**
 * Add drop down actions by status this will add a list of
 * actions for a given status
 * This is a centralized location where we maintain all allowed actions by status of an order.
 * This is very scalable and easy to manage.
 * Lets say we want to turn off delete functionality for a draft order,
 * then we just have remove the ACTION.DELETE from   [PORTIN_ORDER_STATUS.DRAFT.statusCode]: [ACTION.DELETE]
 * The action menu, bulk actions & bucketing logics use this constant instead of maintaining internal checks.
 * So we change the value here and it automatically applies to ellipsis menu, bulk dropdown etc.
 * Another example is that...
 * When an order is in REQUESTED_SUPP status and user selects the checkbox on order table
 * the respective order is added to cancel & schedule activation bucket.
 *
 */
export const ALLOWED_ACTIONS_MENU_ITEMS_BY_STATUS = Object.freeze({
  [PORTIN_ORDER_STATUS.DRAFT.statusCode]: [ACTION.DELETE, ACTION.ADDITIONAL_RECIPIENTS],
  [PORTIN_ORDER_STATUS.PRE_SUBMIT_ERRORS.statusCode]: [
    ACTION.CANCEL,
    ACTION.ADDITIONAL_RECIPIENTS,
    ACTION.SCHEDULE_ACTIVATION_DATE
  ],
  [PORTIN_ORDER_STATUS.READY_FOR_SUBMISSION.statusCode]: [
    ACTION.UPDATE,
    ACTION.DELETE,
    ACTION.SCHEDULE_ACTIVATION_DATE,
    ACTION.ADDITIONAL_RECIPIENTS
  ],
  [PORTIN_ORDER_STATUS.SUBMITTED.statusCode]: [
    ACTION.CANCEL,
    ACTION.ADDITIONAL_RECIPIENTS,
    ACTION.SCHEDULE_ACTIVATION_DATE,
    ACTION.PROVISION_NUMBERS
  ],
  [PORTIN_ORDER_STATUS.EXCEPTION.statusCode]: [
    ACTION.CANCEL,
    ACTION.SCHEDULE_ACTIVATION_DATE,
    ACTION.ADDITIONAL_RECIPIENTS
  ],
  [PORTIN_ORDER_STATUS.REQUESTED_CANCEL.statusCode]: [ACTION.ADDITIONAL_RECIPIENTS],
  [PORTIN_ORDER_STATUS.CANCELLED.statusCode]: [],
  [PORTIN_ORDER_STATUS.FOC.statusCode]: [
    ACTION.CANCEL,
    ACTION.ADDITIONAL_RECIPIENTS,
    ACTION.SCHEDULE_ACTIVATION_DATE,
    ACTION.PROVISION_NUMBERS
  ],
  [PORTIN_ORDER_STATUS.COMPLETED.statusCode]: [ACTION.PROVISION_NUMBERS],
  [PORTIN_ORDER_STATUS.REQUESTED_SUPP.statusCode]: [
    ACTION.CANCEL,
    ACTION.SCHEDULE_ACTIVATION_DATE,
    ACTION.ADDITIONAL_RECIPIENTS,
    ACTION.PROVISION_NUMBERS
  ]
})

/**
 * Show selectable actions for individual performed actions as opposed
 * to bulk actions when viewing individual records under <ManagePortinOrder />.
 */
export const ALLOWED_VIEW_ACTIONS_MENU_ITEMS_BY_STATUS = Object.freeze({
  [PORTIN_ORDER_STATUS.DRAFT.statusCode]: [ACTION.ADDITIONAL_RECIPIENTS, ACTION.VIEW_ORDER_HISTORY],
  [PORTIN_ORDER_STATUS.SUBMITTED.statusCode]: [
    ACTION.CANCEL,
    ACTION.ADDITIONAL_RECIPIENTS,
    ACTION.VIEW_ORDER_HISTORY,
    ACTION.PROVISION_NUMBERS
  ],
  [PORTIN_ORDER_STATUS.REQUESTED_SUPP.statusCode]: [
    ACTION.CANCEL,
    ACTION.ADDITIONAL_RECIPIENTS,
    ACTION.VIEW_ORDER_HISTORY,
    ACTION.PROVISION_NUMBERS
  ],
  [PORTIN_ORDER_STATUS.REQUESTED_CANCEL.statusCode]: [ACTION.VIEW_ORDER_HISTORY, ACTION.ADDITIONAL_RECIPIENTS],
  [PORTIN_ORDER_STATUS.CANCELLED.statusCode]: [ACTION.VIEW_ORDER_HISTORY],
  [PORTIN_ORDER_STATUS.FOC.statusCode]: [
    ACTION.CANCEL,
    ACTION.ADDITIONAL_RECIPIENTS,
    ACTION.VIEW_ORDER_HISTORY,
    ACTION.PROVISION_NUMBERS
  ],
  [PORTIN_ORDER_STATUS.EXCEPTION.statusCode]: [ACTION.CANCEL, ACTION.ADDITIONAL_RECIPIENTS, ACTION.VIEW_ORDER_HISTORY],
  [PORTIN_ORDER_STATUS.COMPLETED.statusCode]: [ACTION.VIEW_ORDER_HISTORY, ACTION.PROVISION_NUMBERS]
})

/**
 * Add NBA (Next Best Action) buttons by status code. This will make sure
 * These buttons are automatically added, without developer intervention
 */
export const ALLOWED_BUTTON_BY_STATUS = Object.freeze({
  [PORTIN_ORDER_STATUS.DRAFT.statusCode]: ACTION.UPDATE,
  [PORTIN_ORDER_STATUS.PRE_SUBMIT_ERRORS.statusCode]: ACTION.UPDATE,
  [PORTIN_ORDER_STATUS.READY_FOR_SUBMISSION.statusCode]: ACTION.SUBMIT,
  [PORTIN_ORDER_STATUS.SUBMITTED.statusCode]: ACTION.VIEW,
  [PORTIN_ORDER_STATUS.EXCEPTION.statusCode]: ACTION.RESOLVE,
  [PORTIN_ORDER_STATUS.CANCELLED.statusCode]: ACTION.VIEW,
  [PORTIN_ORDER_STATUS.FOC.statusCode]: ACTION.VIEW,
  [PORTIN_ORDER_STATUS.COMPLETED.statusCode]: ACTION.VIEW,
  [PORTIN_ORDER_STATUS.REQUESTED_CANCEL.statusCode]: ACTION.VIEW,
  [PORTIN_ORDER_STATUS.REQUESTED_SUPP.statusCode]: ACTION.VIEW
})

export const DATE_CONSTANTS = Object.freeze({
  MIN_SLA_FOR_ORDER_CANCELLATION: 1
})

export const PORTIN_ORDER_HISTORY_TABLE_COLUMN = Object.freeze({
  EVENT: "changedProperty",
  DATE: "eventTime",
  DESCRIPTION: "eventDescription",
  UPDATED_BY: "eventProcessedBy"
})

export const PORTIN_ACTION_STATUS = Object.freeze({
  OPEN: 0,
  APPORVED: 1,
  REJECTED: 2
})

export const PORTIN_ACTION_SORT_BY = Object.freeze({
  PORT_ORDER_ID: "P_O_I"
})

export const PORTIN_ACTION_PAGINATION = Object.freeze({
  OFFSET_ZERO: 0,
  LIMIT_MAX: 25
})

export const PORTIN_ORDER_COUNTRY_DROPDOWNLIST_IDS = Object.freeze({
  US: 1,
  CA: 2
})

/**
 * Allowed expedite order pre statuses can be added as part of ALLOWED_EXPEDITE_ORDER_PRE_STATUSES array
 */
export const ALLOWED_EXPEDITE_ORDER_PRE_STATUSES = Object.freeze([PORTIN_ORDER_STATUS.READY_FOR_SUBMISSION.statusCode])

/**
 * Allowed expedite order FOC statuses can be added as part of ALLOWED_EXPEDITE_ORDER_FOC_STATUSES array
 */
export const ALLOWED_EXPEDITE_ORDER_FOC_STATUSES = Object.freeze([PORTIN_ORDER_STATUS.FOC.statusCode])

/**
 * Specifies the options that the tag clear and copy function accepts
 *
 */
export const TAG_COPY_OPTIONS = Object.freeze({
  ALL: "ALL",
  TRUE: true,
  FALSE: false
})

export const RESTRICTED_EDIT_VIEWS_BY_ORDER_STATUS = Object.freeze([
  PORTIN_ORDER_STATUS.CANCELLED.statusCode,
  PORTIN_ORDER_STATUS.REQUESTED_CANCEL.statusCode,
  PORTIN_ORDER_STATUS.COMPLETED.statusCode,
  PORTIN_ORDER_STATUS.FOC.statusCode
])

export const ORDER_STATUSES_UNDER_UPDATE_CATEGORY = Object.freeze([
  PORTIN_ORDER_STATUS.DRAFT.statusCode,
  PORTIN_ORDER_STATUS.PRE_SUBMIT_ERRORS.statusCode,
  PORTIN_ORDER_STATUS.READY_FOR_SUBMISSION.statusCode
])

export const ORDER_STATUSES_UNDER_EXCEPTION_CATEGORY = Object.freeze([
  PORTIN_ORDER_STATUS.PRE_SUBMIT_ERRORS.statusCode,
  PORTIN_ORDER_STATUS.EXCEPTION.statusCode
])

export const ORDER_STATUSES_UNDER_SUBMITTED_CATEGORY = Object.freeze([
  PORTIN_ORDER_STATUS.SUBMITTED.statusCode,
  PORTIN_ORDER_STATUS.REQUESTED_SUPP.statusCode,
  PORTIN_ORDER_STATUS.REQUESTED_CANCEL.statusCode,
  PORTIN_ORDER_STATUS.FOC.statusCode
])
/**
 * This is derived from NumberRequests > data > constants.js
 * The timeout interval to reset when multiple service requests
 * are batched.
 *
 * Refer:
 * 1. NumberRequests > DIDRequests.js > DidRequestsSummaryHeader &
 * 2. NumberRequests > hooks.js > useDidRequestCount
 */
export const TimeConstants = Object.freeze({
  COUNT_REFRESH_INTERVAL: 60000
})

export const ARROW_ICONS = Object.freeze({
  UP_ARROW: "icon-arrow-up",
  DOWN_ARROW: "icon-arrow-down"
})

/**
 * The bulk actions are restricted for the given statuses.
 * This is applicable on customer and toolbox views
 */
export const RESTRICTED_BULK_ACTION_STATUS = Object.freeze([
  PORTIN_ORDER_STATUS.CANCELLED.statusCode,
  PORTIN_ORDER_STATUS.COMPLETED.statusCode
])

/**
 * All constants values related to Supporting Document are maintained here
 */
export const SUPPORTING_DOCUMENT_CONSTANTS = Object.freeze({
  SUPPORTED_FILE_EXTENSIONS: ".pdf,.txt,.jpg,.tiff,.csv,.xml,.wav,.zip",
  SUPPORTING_DOCUMENT_DATE: 30
})

export const TOOLTIP_TEXT = Object.freeze({
  TOOLTIP_ID: "tooltip_"
})

export const PORT_TYPE = Object.freeze({
  MANNUAL_OFFNET: "MANUAL_OFFNET"
})
