import React, { Component, Fragment } from "react"
import { withRouter } from "react-router-dom"
import ModalErrorGeneral from "components/ModalErrorGeneral"
import HelmetDefaults from "./HelmetDefaults"

export default class BaseLayout extends Component {
  render() {
    const { children, clearError, error } = this.props

    return (
      <Fragment>
        <HelmetDefaults />
        {children}
        <ModalErrorGeneral isOpen={!!error} toggle={clearError} />
      </Fragment>
    )
  }
}

const renderBaseLayout = Component => {
  return props => (
    <BaseLayout>
      <Component {...props} />
    </BaseLayout>
  )
}

export { renderBaseLayout }
